@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;800&display=swap');
.newakefont{
  font-family: 'Jost', sans-serif;
  color: var(--color-body);
}

.raprap{
  display: flex;
  flex-direction: row;
}

.contactheaderds{
  font-family: var(--font-tt-lakes-neue-extended-bold);
  font-size: 25px;
  color: white;
}   
.contactheaderp{
  font-family: var(--font-tt-lakes-neue-trial-regular);
  font-size: 20px;
  color: var(--color-body);

}
.airtop {
  color: white;
  font-size: 70px;
  margin-top: 10px;
  font-family: var(--font-tt-lakes-neue-trial-thin);
}
.airbot {
  font-size: 24px;
  font-family: var(--font-tt-lakes-neue-trial-thin);
  color: var(--color-body);
}

@import "src/assets/scss/default/_variables.scss";

.stripeimage {
  width: 139px;
  height: 696px;
  position: absolute;
  top: -60%;
  left: 15%;
  z-index: 9999;
  object-fit: cover;
  opacity: 0.6;
  animation: fade 4s ease-in-out infinite;
}
.imgplane{
  width: 1000px;
  height: 1000px;
  object-fit: contain;
  margin-top: -380px;
  margin-left: 600px;


}
.indexes,
.tabs {
  list-style-type: none;



}

.gradient-border {
  width: 100%; /* Genişlik */
  height: 20px; /* Yükseklik */
  background: linear-gradient(to right, yellow, white, yellow);
  border-radius: 20px; /* Kenar yuvarlama */
  padding: 5px; /* İç boşluk */
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.gradient-border::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit;
  border-radius: inherit;
  margin: -5px; /* Kenar genişliği */
  z-index: -1;
}

.contentborder {
  width: 100%;
  height: 100%;
  background: transparent;
}
#earth-canvas{
  height: 100%;
  width: 100%;
  background: #010826;
  position: relative;
  z-index: 5;
}

#html2canvas {
  position: absolute;
  z-index: -1;
  left: 0;
  top:0;
  background: rgba(0, 0, 0, 0);
}
.css3d-wapper {
  pointer-events: none;
  color: #fff;
}
.rn-newsletter-default{
  position: relative;
}
.css3d-wapper .fire-div {
  font-size: 50px;
  border-top: 3px solid #0cd1eb;
  font-family: var(--font-tt-lakes-neue-extended-demibold);
  background: rgba(40, 108, 181, 0.5);
  padding: 5px;
  padding-left: 15px;
  padding-right: 20px;

}
.outtext{
  position: absolute;
  top:20%;
  right: -20%;
  width: 40%;
  font-size: 110px;
  opacity: .4;
  line-height:110px;
  font-family: var(--font-tt-lakes-neue-extended-black);
  pointer-events: none;
  z-index: -2;
  color: var(--color-dark);
  filter: blur(2px);
  text-shadow: -1px -1px 0 var(--color-primary), 1px -1px 0 var(--color-primary), -1px 1px 0 var(--color-primary), 1px 1px 0 var(--color-primary);
}
.buttonnewhomefinance {
  position: absolute;
  top:60%;
  right: -20%;
  border: none;
  padding: 10px 20px;
  font-size: 30px;
  background: transparent;
  font-family: var(--font-tt-lakes-neue-extended-regular);
  color: #ffa500;
  text-transform: uppercase;
  border: 3px solid #ffa500;
  cursor: pointer;
  transition: all 0.7s;
  overflow: hidden;
  border-radius: 0;
}

.buttonnewhomefinance:hover {
  color: #000;
}
.buttonnewhomefinance > span {
  transition: all 0.7s;
  z-index: -1;
}

.buttonnewhomefinance .first {
  content: "";
  position: absolute;
  right: 100%;
  top: 0;
  width: 25%;
  height: 100%;
  background: #ffa500;
}

.buttonnewhomefinance:hover .first {
  top: 0;
  right: 0;
}
.buttonnewhomefinance .second {
  content: "";
  position: absolute;
  left: 25%;
  top: -100%;
  height: 100%;
  width: 25%;
  background: #ffa500;
}

.buttonnewhomefinance:hover .second {
  top: 0;
  left: 50%;
}

.buttonnewhomefinance .third {
  content: "";
  position: absolute;
  left: 50%;
  height: 100%;
  top: 100%;
  width: 25%;
  background: #ffa500;
}

.buttonnewhomefinance:hover .third {
  top: 0;
  left: 25%;
}

.buttonnewhomefinance .fourth {
  content: "";
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  width: 25%;
  background: #ffa500;
}

.buttonnewhomefinance:hover .fourth {
  top: 0;
  left: 0;
}

.outtext2{
  position: absolute;
  top: 40%;
  right:-20%;
  width: 40%;
  font-size:80px;
  line-height:70px;
  font-family: var(--font-tt-lakes-neue-compressed-demibold);
  z-index: -2;
  color: white;
}
.imggaboutt
{
  width: 100%;
  height: 70vh;
  object-fit: cover;
  object-position: center center;
}
#loading{
  position:absolute;
  left:0;
  top:0;
  right:0;
  bottom: 0;
  width: 100vw;
  height:100vh;
  z-index: 999;
  background:#010826;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color:#409EFF;
  font-size: 15px;
  letter-spacing: 2px;
  overflow: hidden;
}
@keyframes zoomOut {
  0%{
    opacity:1
  }
  50%{
    opacity:0;
    transform:scale3d(1.3,1.3,1.3)
  }
  to{
    opacity:0
  }
}
#loading.out{
  animation:zoomOut 0.5s  linear forwards;
  pointer-events: none;
}
#loading.out .sk-chase-dot,
#loading.out .sk-chase{
  animation: null;
}
.sk-chase {
  margin-bottom: 20px;
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}

.sk-chase-dot::before {
  content: '';
  display: block;
  width: 20%;
  height: 20%;
  background-color: #409EFF;
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}
.sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2) {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}

.sk-chase-dot .sk-chase-dot:nth-child(2) {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}

@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}
.indexes {
  font-size: 2rem; /* İki katına çıkardık */

  & li {
    padding: 2rem; /* İki katına çıkardık */
    border: 2px solid transparent; /* İki katına çıkardık */
    cursor: pointer;
  }
}

.tabs {
  position: relative;
}

.tab {
  position: absolute;
  display: flex;
  height: auto; /* İki katına çıkardık */
  opacity: 0;
  background-color: red;
  border-radius: 20px;
  overflow: hidden;
}

.moon {
  height: 600px; /* İki katına çıkardık */
  font: 1.4rem impact, sans-serif; /* İki katına çıkardık */
  position: relative;
  z-index: 5;

  & header {
    font-size: 2.4rem; /* İki katına çıkardık */
    text-transform: uppercase;
    color: white;

    & span {
      color: var(--primary);
    }
  }

  & section {
    display: flex;
    gap: 4rem; /* İki katına çıkardık */
  }
}
.btnnn {
  
  color: #fff;
  cursor: pointer;
  // display: block;
  font-size:16px;
  font-weight: 400;
  line-height: 45px;
  padding: 0 0 2em;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;


  
  @media(min-width: 600px) {
      
    padding: 0 1em 2em;
  

  }
  
  &:hover { text-decoration: none; 
  }
  
}



.tab-content svg {
  height: auto; /* SVG'nin yüksekliğinin orantılı olmasını sağlar */
}

.btnnn-1 {
  background:transparent;
  font-weight: 100;
  color: var(--color-body);
  svg {
    height: 45px;
    left: 0;
    position: absolute;
    top: 0; 
    width: 163px;

  }
  
  rect {
    fill: none;
    stroke: #fff;
    stroke-width: 2;
    stroke-dasharray: 422, 0;
    transition: all 0.35s linear;
  }
}

.btnnn-1:hover {
  color: var(--color-body);
  rect {
    stroke-width: 5;
    stroke-dasharray: 15, 310;
    stroke-dashoffset: 48;
    transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
  }
}
.btnnn-1 a{
  width: auto;
  margin-left: 10px;
}
.btnnn-1 a:hover{
  color: var(--color-body);
}


.tab-content {
  position: relative;
  z-index: 10; /* İki katına çıkardık */
  width: 600px; /* İki katına çıkardık */
  display: flex;
  flex-direction: column;
  gap: 1rem; /* İki katına çıkardık */
  opacity: 0;
  transform: translateY(-10rem); /* İki katına çıkardık */

  & h3 {
    font-weight: 900;
    font-size: 2rem; /* İki katına çıkardık */
    border-bottom: 3px solid white; /* İki katına çıkardık */
    padding-bottom: 2rem; /* İki katına çıkardık */
  }

  & p {
    font-weight: 300;
    line-height: 2; /* İki katına çıkardık */
    color: rgba(255, 255, 255, 0.7);


  }

  & button {
    width: fit-content;
    background-color: transparent;
    color: white;
    border: 2px solid white; /* İki katına çıkardık */
    font-size: 1.4rem; /* İki katına çıkardık */
    padding: 1.5rem 2rem; /* İki katına çıkardık */
    cursor: pointer;
  }
}

@keyframes content {
  100% {
    opacity: 1;
    transform: translateY(0);

  }
}

.tab-image {
  position: absolute;
  right: 2rem; /* İki katına çıkardık */
  width: 400px; /* İki katına çıkardık */
  height: 400px; /* İki katına çıkardık */
  opacity: 0;
  transform: translateX(4rem); /* İki katına çıkardık */

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background-color: var(--overlay);
    mix-blend-mode: multiply;
  }

  & img {
    width: inherit;
    height: inherit;
    object-fit: cover;
    object-position: left;

  }
}
.margleft{
  margin-left: 10px;
}
.margright{
  margin-right: 10px;
}

@keyframes image {
  100% {
    opacity: 1;
    width: 600px; /* İki katına çıkardık */
    transform: translateX(0);
  }
}

.active .tab {
  opacity: 1;
  z-index: 10; /* İki katına çıkardık */
}

.active .tab-content {
  animation: content 0.9s ease-out 0.4s forwards;
}

.active .tab-image {
  animation: image 1s ease-out forwards;
}

@keyframes fade {
  0%, 100% {
    opacity: 0.6;
    content: url('http://localhost:3000/images/bg/strip5.png');
  }
  50% {
    opacity: 0.6;
    content: url('http://localhost:3000/images/bg/strip6.png');
  }
}

.barr-container {
  display: flex;
  align-items: center;
  height: 670px;
  position: absolute;
  left: 15%;
  top: -35%;
}
.cabcap{
  display: flex;
  flex-direction: row;
}
.cwxsdds{
  font-family: var(--color-primary);
  color: var(--color-body);
}
.brraa{
  width: 100%;
  height: 92vh;
  margin-left: -450px;
}
.sxsdddd{

}

.newbigdivinner{
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 60%;
  padding-bottom: 30px;
  background-color: rgba(188, 188, 188,.9);
}

@media (max-width: 1671px) {
  .maphold {
    width: 100vw !important;
    border: 1px solid transparent;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    overflow: hidden;
    margin-left: 0 !important;
}
  .logo222{

    width: 350px;
    position: relative;
    z-index: 2;
    margin-left: 0 !important;
  
  }
}
@media (max-width: 1571px) {
  .buttonnewhomefinance {
    position: absolute;
    top:70%;
    right: -20%;

    border-radius: 0;
  }
  .brraa{
    width: 100%;
    height: 92vh;
    margin-left: -420px;
  }
 
}
@media (min-width: 1281px) {
  .damboyinno{
    display: none;
  }
  .damboyx{
    display: none;
  }
}
@media (max-width: 1281px) {
  .outtext{
    position: absolute;
    top:10%;
    right: -20%;
    width: 40%;
    line-height:110px;

  }
  .brraa{

    margin-left: -370px;
  }
  .outtext2{
    position: absolute;
    top: 25%;
    right:-25%;
    width: 40%;
    font-size: 85px;
    line-height:70px;

  }
  
    .damboy{
      display: none;
    }
    .damboyx{
      display: block;
    }
  
    .header-right {
 
      padding-right: 20px;
  }
  .raprap
  {
    display: flex ;
    flex-direction: column;
  }
  .hamberger-button {
    background-color: transparent;
    border: 0 none;
    color: var(--color-white);
    width: 60px;
    height: 60px;
    font-size: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    cursor: pointer;
}

.cwxsdds{
  font-size: 19px;
}
}

@media (max-width: 1025px) {
  .outtext{
    position: absolute;
    top:10%;
    left: 0;
    width: 100%;
    line-height:110px;
    font-size: 92px;
  }
  .rn-header{
    padding-left: 40px;
    padding-right: 20px;
  }
.newbigdivinner{
  width: 100%;
}
  .brraa{

    margin-left: 0;
 
  }
  .outtext2{
    position: absolute;
    top: 25%;
    left:0%;
    width: 100%;
    height: 92vh;
    font-size: 140px;
    line-height:110px;
    z-index: 4;
    background-color: rgba(0, 0, 0,.4);
    padding: 100px;
  }
  .buttonnewhomefinance {
    position: absolute;
    top:70%;
    right: 20%;
    padding: 10px 20px;
    font-size: 50px;
    border-radius: 0;
    z-index: 5;

  }
.moon {
    height: auto;
    font: 1.4rem impact, sans-serif;
    position: relative;
    z-index: 5;
}
  
  .wrapperstart {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .containerstart {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: start;
  }
  .cardstart {
    width: 100% !important;
    height: 25%;
    background-size: cover;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    transition: .6s cubic-bezier(.28,-0.03,0,.99);
    box-shadow: 0px 10px 30px -5px rgba(0,0,0,0.8);
    position: relative;
  }

.cardstart.active {
  height: 100%;

}
.cardstart > .row > .icon
{
  bottom: 20px;
}

  
}
@media (max-width: 900px) {

}
@media (max-width: 821px) {
  .outtext{
    position: absolute;
    top:10%;
    left: 0;
    width: 100%;
    line-height:110px;
    font-size: 92px;
  }

  .brraa{

    margin-left: 0;
 
  }
  .outtext2{
    position: absolute;
    top: 25%;
    left:0%;
    width: 100%;
    height: 92vh;
    font-size: 100px;
    line-height:90px;
    z-index: 4;
    background-color: rgba(0, 0, 0,.4);
    padding: 100px;
  }
  .buttonnewhomefinance {
    position: absolute;
    top:80%;
    right: 20%;
    padding: 10px 20px;
    font-size: 50px;
    border-radius: 0;
    z-index: 5;

  }

}
@media (max-width: 768px) {
  .logo222{

    width: 300px !important;
    position: relative;
    z-index: 2;
    margin-left: 0 !important;
  
  }
  .rn-header {
    padding-left: 5px;
    padding-right: 20px;
}
  .margleft{
    margin-left: 0;
  }
  .margright{
    margin-right: 0;
  }
  .cardstart > .row > .description {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
    overflow: hidden;
    width: 100%;
    opacity: 0;
    margin-left: 0;
    font-family: var(--font-tt-lakes-neue-trial-extrabold);
  
    transition-delay: .3s;
    transition: all .3s ease;
  }

  .description h4 {
    text-transform: uppercase;
    padding: 0;
    margin:0;
    font-size: 30px !important;
    font-family: var(--font-tt-lakes-neue-extended-demibold);
  
  }
  .description p {
   font-size: 16px !important;
  
  }
  .cardstart {
    width: 100% !important;
    height: 10%;
    background-size: cover;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    transition: .6s cubic-bezier(.28,-0.03,0,.99);
    box-shadow: 0px 10px 30px -5px rgba(0,0,0,0.8);
    position: relative;
  }
  .cardstart.active {
    height: 500px;
  
  }
  .cardstart > .row > .icon
{
  bottom: 50px;
}
  .brraa{

    margin-left: 0;
 
  }
  .unordcontainerinner{
    max-height: 1020px;
  }
  .outtext2{
    position: absolute;
    top: 25%;
    left:0%;
    width: 100%;
    height: 92vh;
    font-size: 85px;
    line-height:90px;
    z-index: 4;
    background-color: rgba(0, 0, 0,.4);
    padding: 20px;
  }
  .buttonnewhomefinance {
    position: absolute;
    top:74%;
    right: 10%;
    padding: 5px 10px;
    font-size: 30px;
    border-radius: 0;
    z-index: 5;

  }

}
@media (max-width: 430px) {
  .rn-header {
    padding-left: 0;
    padding-right: 0;
}
}

@media (max-width: 411px) {
  .unordcontainerinner{
    max-height: 820px;
  }
  .cardstart {
    width: 100% !important;
    height: 7%;
    background-size: cover;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    transition: .6s cubic-bezier(.28,-0.03,0,.99);
    box-shadow: 0px 10px 30px -5px rgba(0,0,0,0.8);
    position: relative;
  }
  .brraa{

    margin-left: 0;
 
  }
  .outtext2{
    position: absolute;
    top: 25%;
    left:0%;
    width: 100%;
    height: 92vh;
    font-size: 77px;
    line-height:70px;
    z-index: 4;
    background-color: rgba(0, 0, 0,.4);
    padding: 20px;
  }
  .buttonnewhomefinance {
    position: absolute;
    top:74%;
    right: 10%;
    padding: 5px 10px;
    font-size: 25px;
    border-radius: 0;
    z-index: 5;

  }

}
/* Ekran genişliği 1600px'in altında olduğunda elementi gizle */
@media (max-width: 1600px) {
  .barr-container {
    z-index: -2;
  }
}
@media (max-width: 971px) {
  .barr-container {
    display: none;
  }
}
.barr, .barr2 {
  width: 18px;
  height: 670px;
  background: linear-gradient(to bottom, red 35%, #8B0000 55%, #5c0101, #3d0101, #2b0000, #140101);
  clip-path: polygon(50% 0%, 100% 100%, 50% 100%);

}

.barr {
  animation: moveUpDown 3s infinite ease-in-out;
}

.barr2 {
  animation: downup 3s infinite ease-in-out;
}

@keyframes downup {
  0%, 20%, 100% {
    transform: translateY(-25px);
  }
  40%, 60% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(-25px);
  }
}

@keyframes moveUpDown {
  0%, 20%, 100% {
    transform: translateY(0);
  }
  40%, 60% {
    transform: translateY(-25px);
  }
  80% {
    transform: translateY(0);
  }
}


.headingthird{
  font-size: 65px;
  font-weight: 300;
  margin-top: 140px;
}
.unordcontainer {
    width: 100%;
    height: auto;
    margin-top: -10px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: flex;
    justify-content: flex-end;
    z-index: 8;
    padding: 40;
    background-color: transparent;
  border-top-right-radius: 120px;
  border-top-left-radius: 120px;
  overflow: hidden;
  min-height: 50vh;

}
.unordcontainerinner{
  width: 100%;
  background-color: #f6f6f6;
  border-top-right-radius: 120px;
  border-top-left-radius: 120px;
  background-image: linear-gradient(315deg, #f6f6f6 0%, #e9e9e9 74%);


}
.unüst {
  position: relative;
  width: 100%;
  background-color: #f6f6f6;
  border-top-right-radius: 120px;
  border-top-left-radius: 120px;
  height: 100%;
  background-image: linear-gradient(315deg, #233d5d 0%, #2c4a72 74%);
  clip-path: polygon(0 0, 49% 0%, 49% 2%, 49% 5%, 48% 7%, 45% 9%, 22% 9%, 17% 9%, 16% 13%, 16% 17%, 16% 57%, 17% 61%, 19% 66%, 22% 71%, 25% 75%, 30% 78%, 35% 81%, 40% 82%, 45% 85%, 49% 87%, 52% 89%, 55% 93%, 56% 97%, 57% 100%, 0% 100%);
}

.wrapperstart {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerstart {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: start;
}

.cardstart {
  width: 90px;
  background-size: cover;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  transition: .6s cubic-bezier(.28,-0.03,0,.99);
  box-shadow: 0px 10px 30px -5px rgba(0,0,0,0.8);
}

.cardstart > .row {
  color: white;
  display: flex;
  flex-wrap: nowrap;
  padding-top: 20px;
}

.cardstart > .row > .icon {
  position: absolute;
  bottom: 0;
  background: #223;
  color: white;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px;
  margin-left: 25px;
}

.cardstart > .row > .description {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
  overflow: hidden;
  width: 100%;
  opacity: 0;
  margin-left: 90px;
  transform: translateY(30px);
  font-family: var(--font-tt-lakes-neue-trial-extrabold);

  transition-delay: .3s;
  transition: all .3s ease;
}

.description p {
  color: #c6c5c5;
  font-family: var(--font-tt-lakes-neue-trial-medium);
  width: 65%;

}

.description h4 {
  text-transform: uppercase;
  padding: 0;
  margin:0;
  font-size: 40px;
  font-family: var(--font-tt-lakes-neue-extended-demibold);

}

.cardstart.active {
  width: 100%;

}
.cardstart.active > .row {
    background-color: rgba(26, 26, 26,.3);
}

.cardstart.active .description {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.pipe {
  position: relative;
  width: 200px; /* Borunun genişliği */
  height: 20px; /* Borunun yüksekliği */
  background: repeating-linear-gradient(
      45deg,
      #ccc,
      #ccc 10px,
      #999 10px,
      #999 20px
  );
  border-radius: 10px; /* Borunun köşelerini yuvarlama */
  overflow: hidden;
}

.pipe::before,
.pipe::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background: radial-gradient(circle, #888, #444);
  top: 0;
  border-radius: 50%;
}

.pipe::before {
  left: -10px; /* Sol ucu */
}

.pipe::after {
  right: -10px; /* Sağ ucu */
}
.firstpack{
  background-color:#01326b;
  width: 40%;                         
  height: 9%;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-bottom-right-radius: 80px;
}
.secondpack{
  background-color:#01326b;
  width: 22.5%;
  height: 100%;
  position: absolute;
  top: 15%;
  left: 0;

}
.firstpack{
  background-color:#01326b;
  width: 40%;
  height: 15%;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-bottom-right-radius: 80px;
}
.lastpack{
  background-color:#01326b;
  width: 45%;
  height: 16%;
  position: absolute;
  bottom: 0;
  left: 0;
  border: 1px solid transparent;
  clip-path: polygon(0% 0%, 40% 0%, 45% 15%, 50% 40%, 100% 100%, 0% 100%);


}
.services-list-container{
    float: right;

}
.services-listtt {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
    position: relative;
    padding: 15px;


}
.classdesign{
  
  background: var(--color-dark);
  position: relative;
  z-index: 2;
  border: 1px solid transparent;
  border-radius: 40px;
  margin-bottom: 60px;

}
.logo{
  width: 350px;
  position: relative;
  z-index: 2;
}
.logo222{
  width: 350px;
  position: relative;
  z-index: 2;
  margin-left: 56%;
}
.service-carddd {
    background-color:black;

    text-align: center;
    transition: 0.3s;
    height: 220px;
    width: 330px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Kart içeriğini dikeyde ortalamak için kullanıldı */
    align-items: center; /* Kart içeriğini yatayda ortalamak için kullanıldı */
    position: relative; /* Görsel açıdan çapraz hat çizmek için kullanıldı */


}




.service-carddd:hover {
}

.service-titleee {
    font-size: 16px;
    color:var(--color-body);

}

.service-descriptionnn {
    font-size: 12px;
    color: black;

}

.card {
    width: 100%; /* Adjust as needed */
    height: 400px;
    background: transparent;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    display: inline-block;
  }
  
  .card_left,
  .card_middle,
  .card_right {
    height: 100%;
    float: left;
    overflow: hidden;
  }
  
  .card_left {
    width: 30%;
    background: transparent;
  
    &__button {
      width: 100%;
      padding: 20px;
    }
  }
  
  .card_middle {
    width: 35%;
    background: transparent;
    border-color: var(--color-body);
    border: 1px solid;
    border-right: none;


    img {
      width: 100%;
      height: 400px;
      object-fit: cover;
      object-position: top;
    }
  }
  
  .card_right {
    width: 35%;
    float: left;
    background: #000000;
    height: 100%;
    border-color: var(--color-body);

    border: 1px solid;


  
    h1 {
      color: var(--color-body);
      font-family: 'Jost', sans-serif;
      font-weight: 600;
      text-align: left;
      font-size: 40px;
      margin: 30px 0 0 0;
      padding: 0 0 0 40px;
      letter-spacing: 1px;
    }
    &__details {
      ul {
        list-style-type: none;
        padding: 0 0 0 40px;
        margin: 10px 0 0 0;
        
        li {
          display: inline;
          color: var(--color-body);
          font-family: 'Jost', sans-serif;
          font-weight: 400;
          font-size: 14px;
          padding: 0 40px 0 0;
        }
      }
    }
  
    &__rating {
      &__stars {
        position: relative;
        right: 160px;
        margin: 10px 0 10px 0;
  
        fieldset, label {
          margin: 0;
          padding: 0;
        }
  
        .rating {
          border: none;
        }
  
        .rating > input {
          display: none;
        }
  
        .rating > label:before {
          margin: 5px;
          font-size: 1.25em;
          display: inline-block;
          content: "\f005";
          font-family: FontAwesome;
        }
  
        .rating > .half:before {
          content: "\f089";
          position: absolute;
        }
  
        .rating > label {
          color: #ddd;
          float: right;
        }
  
        /***** CSS Magic to Highlight Stars on Hover *****/
  
        .rating > input:checked ~ label,
        .rating:not(:checked) > label:hover,
        .rating:not(:checked) > label:hover ~ label {
          color: red;
        }
  
        .rating > input:checked + label:hover,
        .rating > input:checked ~ label:hover,
        .rating > label:hover ~ input:checked ~ label,
        .rating > input:checked ~ label:hover ~ label {
          color: red;
        }
      }
    }
  
    &__review {
      p {
        color: var(--color-body);        
        font-family: 'Jost', sans-serif;
        width: 80%;
        word-wrap: break-word;
        font-size: 13px;
        padding: 0 40px 0 40px;
        letter-spacing: 1px;
        margin: 10px 0 10px 0;
        line-height: 20px;
      }
  
      a {
        text-decoration: none;
        font-family: 'Jost', sans-serif;
        font-size: 14px;
        padding: 0 0 0 40px;
        color: red;
        margin: 0;
      }
    }
  
    &__button {
      a {
        color: red;
        text-decoration: none;
        font-family: 'Montserrat', sans-serif;
        border: 2px solid red;
        padding: 10px 10px 10px 40px;
        font-size: 12px;
        background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/343086/COMdoWZ.png);
        background-size: 12px 12px;
        background-repeat: no-repeat;
        background-position: 7% 50%;
        border-radius: 5px;
        -webkit-transition-property: all;
        transition-property: all;
        -webkit-transition-duration: .5s;
        transition-duration: .5s;
      }
  
      a:hover {
        color: #000000;
        background-color: red;
        background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/343086/rFQ5dHA.png);
        background-size: 12px 12px;
        background-repeat: no-repeat;
        background-position: 10% 50%;
        cursor: pointer;
        -webkit-transition-property: all;
        transition-property: all;
        -webkit-transition-duration: .5s;
        transition-duration: .5s;
      }
  
      padding: 0 0 0 40px;
      margin: 30px 0 0 0;
    }
  }
.bambo{
  text-align: left
}
.spariya{

}
.vixad{

}
.dixad{

}
.padi{

}
.zra{
  display: flex;
  justify-content: flex-end;
}
.gapbeka{
  padding-top: 70px;
}
.infinite-scroll {
  margin-right: 20px; /* Öğenin içine sağdan 10px boşluk ekler */
  box-sizing: border-box; /* padding'i genişliğe dahil etmek için */
}
.infinite-scroll::-webkit-scrollbar {
  width: 10px; /* Kaydırma çubuğunun genişliği */
  margin-right: 10px;
}

/* Özel sınıf için kaydırma çubuğunun içindeki kaydırıcı (thumb) kısmını özelleştirir */
.infinite-scroll::-webkit-scrollbar-thumb {
  background-color: var(--color-primary); /* Kaydırıcı rengi */
  height: 10px;
}

/* Özel sınıf için kaydırma çubuğunun arka planını (track) özelleştirir */
.infinite-scroll::-webkit-scrollbar-track {
  
background-color: rgba(2, 0, 140, 0.2); /* Çubuğun arka plan rengi */
}
.vertical-bar {

  width: 5px; /* Çubuğun genişliği */
  height: 300px; /* Çubuğun yüksekliği */
  background-color: rgba(5, 88, 255,0.2); /* Çubuğun arka plan rengi */
  position: relative;
  overflow: hidden; /* Kare çubuğun dışına çıktığında gizlenir */
  margin-right: 50px;
  margin-top: 40px;

}

.moving-square {
  
  width: 10px; /* Karenin genişliği */
  height: 10px; /* Karenin yüksekliği */
  background-color:var(--color-primary); /* Karenin rengi */
  box-shadow: 0 0 15px 5px var(--color-primary); 

  position: absolute;
  bottom: 0; /* Başlangıç pozisyonu */
  animation: moveUpDownn 12s linear infinite; /* Animasyon */
}

@keyframes moveUpDownn {
  0%, 100% {
    bottom: 0;
  }
  50% {
    bottom: 280px; /* Çubuğun yüksekliğine göre ayarlayın */
  }
}
.dribba{
  display: flex;
}
.fikar{
  color: var(--color-primary);
  font-size: 28px;
  position: absolute;
  top: -60px;
}

.language-dropdown {
  background-color: black;
  color: white;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 5px;
  -webkit-appearance: none; /* Tarayıcı özel stillerini kaldırır */
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right 10px center;
}
.rart{

}

.xxxweee{
  width: 550px;
}


    @media (max-width: 576px) {
      .dribba{
        display: grid;
      }
      .rart{
          width: 100%;
      }
    .moon {
      display: grid; /* Flex yerine block yapısı kullan */
      grid-template-rows: auto 1fr; /* İndeksler üstte, sekmeler altta olacak şekilde ayarlar */
      width: 100%;
      height: auto;
      margin-top: 0;
  
    }

    .xxxweee{
      width: 430px;
      
    }
    .moon section{
      display: grid; /* Flex yerine block yapısı kullan */
      grid-template-rows: auto 1fr; /* İndeksler üstte, sekmeler altta olacak şekilde ayarlar */
      gap: 0px;
    }
    .tab-content{
      width: 350px;
      height: auto;
    }
    .padi{
      margin-right:5px;
    }
    .zra{
      display: none;
    }
    .vixad{
  width:80% !important; 
    }
    .dixad{
      width:18% !important; 

    }
    
    .spariya{
      margin-left: 16px;
    }
    .active .tab-content{
  
      width: 350px;
      height: auto;
  
    }
    .bambo{
      text-align: center;
    }
 
    .tab{
      width: 100%;
      height: auto;
    }
    .active .tab-image img {
      opacity: 0.4;
    }
    .active .tab-content p {
      color:rgba(255, 255, 255, 0.9);
      background: rgba(0,0,0,0.2);
      height: 410px;
      overflow: hidden;
    }
    .tab-image {
      left: 0;
    }
    .sec{
     
    }
    .indexes {
      display: flex;
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 20px; 
    }
   
    .indexes li {
      width: 90px;
    }
  
    .tabs {
      /* Sekmelerin altta sıralanması için ayarlar */
    }
  }

.rwt-contact-area
{
  position: relative;
  z-index: 3;
  padding-bottom: 35px;
}
.maphold{
  width: 70%;
  margin-left: -80px;
  border:1px solid transparent;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  overflow: hidden;
}