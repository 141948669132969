/*-- Variables --*/

@font-face {
  font-family: 'TT Lakes Neue Compressed Black';
  src: url('/assets/fonts/TT Lakes Neue Trial Compressed Black.ttf') format('truetype');
}

@font-face {
  font-family: 'TT Lakes Neue Compressed Bold';
  src: url('/assets/fonts/TT Lakes Neue Trial Compressed Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'TT Lakes Neue Compressed DemiBold';
  src: url('/assets/fonts/TT Lakes Neue Trial Compressed DemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'TT Lakes Neue Compressed ExtraBold';
  src: url('/assets/fonts/TT Lakes Neue Trial Compressed ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'TT Lakes Neue Compressed ExtraBold';
  src: url('/assets/fonts/TT Lakes Neue Trial Compressed ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'TT Lakes Neue Compressed Light';
  src: url('/assets/fonts/TT Lakes Neue Trial Compressed Light.ttf') format('truetype');
}
@font-face {
  font-family: 'TT Lakes Neue Compressed Medium';
  src: url('/assets/fonts/TT Lakes Neue Trial Compressed Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'TT Lakes Neue Compressed Regular';
  src: url('/assets/fonts/TT Lakes Neue Trial Compressed Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'TT Lakes Neue Compressed Thin';
  src: url('/assets/fonts/TT Lakes Neue Trial Compressed Thin.ttf') format('truetype');
}
@font-face {
  font-family: 'TT Lakes Neue Condensed Black';
  src: url('/assets/fonts/TT Lakes Neue Trial Condensed Black.ttf') format('truetype');
}
@font-face {
  font-family: 'TT Lakes Neue Condensed Bold';
  src: url('/assets/fonts/TT Lakes Neue Trial Condensed Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'TT Lakes Neue Condensed DemiBold';
  src: url('/assets/fonts/TT Lakes Neue Trial Condensed DemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'TT Lakes Neue Condensed ExtraBold';
  src: url('/assets/fonts/TT Lakes Neue Trial Condensed ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'TT Lakes Neue Condensed Light';
  src: url('/assets/fonts/TT Lakes Neue Trial Condensed Light.ttf') format('truetype');
}
@font-face {
  font-family: 'TT Lakes Neue Condensed Medium';
  src: url('/assets/fonts/TT Lakes Neue Trial Condensed Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'TT Lakes Neue Condensed Regular';
  src: url('/assets/fonts/TT Lakes Neue Trial Condensed Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'TT Lakes Neue Condensed Thin';
  src: url('/assets/fonts/TT Lakes Neue Trial Condensed Thin.ttf') format('truetype');
}
@font-face {
  font-family: 'TT Lakes Neue Extended Black';
  src: url('/assets/fonts/TT Lakes Neue Trial Extended Black.ttf') format('truetype');
}

@font-face {
  font-family: 'TT Lakes Neue Extended Bold';
  src: url('/assets/fonts/TT Lakes Neue Trial Extended Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'TT Lakes Neue Extended DemiBold';
  src: url('/assets/fonts/TT Lakes Neue Trial Extended DemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'TT Lakes Neue Extended ExtraBold';
  src: url('/assets/fonts/TT Lakes Neue Trial Extended ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'TT Lakes Neue Extended Light';
  src: url('/assets/fonts/TT Lakes Neue Trial Extended Light.ttf') format('truetype');
}
@font-face {
  font-family: 'TT Lakes Neue Extended Medium';
  src: url('/assets/fonts/TT Lakes Neue Trial Extended Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'TT Lakes Neue Extended Regular';
  src: url('/assets/fonts/TT Lakes Neue Trial Extended Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'TT Lakes Neue Extended Thin';
  src: url('/assets/fonts/TT Lakes Neue Trial Extended Thin.ttf') format('truetype');
}




@font-face {
  font-family: 'TT Lakes Neue Black';
  src: url('/assets/fonts/TT Lakes Neue Trial Black.ttf') format('truetype');
}

@font-face {
  font-family: 'TT Lakes Neue Bold';
  src: url('/assets/fonts/TT Lakes Neue Trial Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'TT Lakes Neue DemiBold';
  src: url('/assets/fonts/TT Lakes Neue Trial DemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'TT Lakes Neue ExtraBold';
  src: url('/assets/fonts/TT Lakes Neue Trial ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'TT Lakes Neue Light';
  src: url('/assets/fonts/TT Lakes Neue Trial Light.ttf') format('truetype');
}
@font-face {
  font-family: 'TT Lakes Neue Medium';
  src: url('/assets/fonts/TT Lakes Neue Trial Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'TT Lakes Neue Regular';
  src: url('/assets/fonts/TT Lakes Neue Trial Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'TT Lakes Neue Thin';
  src: url('/assets/fonts/TT Lakes Neue Trial Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'LEMONMILK-Bold';
  src: url('/assets/fonts/LEMONMILK-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'LEMONMILK-BoldItalic';
  src: url('/assets/fonts/LEMONMILK-BoldItalic.otf') format('opentype');
}

@font-face {
  font-family: 'LEMONMILK-Light';
  src: url('/assets/fonts/LEMONMILK-Light.otf') format('opentype');
}
@font-face {
  font-family: 'LEMONMILK-Medium';
  src: url('/assets/fonts/LEMONMILK-Medium.otf') format('opentype');
}
@font-face {
  font-family: 'LEMONMILK-Regular';
  src: url('/assets/fonts/LEMONMILK-Regular.otf') format('opentype');
}
html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
}
:root {
    //Themes colors
    --color-primary: #0558ff;
    --color-primary-rgba: #0558ff;
    --color-primary-alt: #0558ff;
    --color-primary-light: #0558ff;
    --color-primary-lightest: #0558ff;
    --color-secondary: #0558ff;
    --color-tertiary: #0558ff;
    --color-tertiary-alt: #02316b;
    --color-pink: #0558ff;
    --color-light-green: #333942;
    --color-primary-darker: #011827;
    --color-tertiary-darker: #150b40;
    --color-secondary-darker: #190301;
    --color-light-green-darker: #001f27;
    --color-pink-darker: #2f011d;

    // Typo Color 
    --color-heading: #ffffff;
    --color-body: #c7c4c4;
    --color-body2: #2828289a;


    --color-dark: #010826;
    --color-darker: rgba(6, 6, 6, 0.7);
    --color-darkest: rgba(0, 0, 0, 0.3);

    --color-black: #000000;
    --color-blacker: rgba(15, 15, 17, 0);
    --color-blackest: #ffffff8c;
    --color-border: hsla(0, 0%, 100%, 0.1);


    --color-lessdark: #1e1e1e;

    .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop{
        background-color: rgba(0, 0, 0, 0.2);
    }


    // Gery Colors 
    --color-gray: #65676B;
    // --color-midgray: #9F9F9F;
    --color-midgray: #878787;
    --color-light: #E4E6EA;
    --color-lighter: #CED0D4;
    --color-lightest: #F0F2F5;

    --color-white: #000000;
    


    // Notify Colors 
    --color-success: #3EB75E;
    --color-danger: #FF0003;
    --color-warning: #FF8F3C;
    --color-info: #1BA2DB;


    //Social icon colors
    --color-facebook: #3B5997;
    --color-twitter: #1BA1F2;
    --color-youtube: #ED4141;
    --color-linkedin: #0077B5;
    --color-pinterest: #E60022;
    --color-instagram: #C231A1;
    --color-vimeo: #00ADEF;
    --color-twitch: #6441A3;
    --color-discord: #7289da;


    // Extra Color 
    --color-extra01: #666666;
    --color-extra02: #606770;
    --color-extra03: #FBFBFD;
    --color-extra04: #1A1A1A;
    --color-extra05: #242424;
    
    //Border-radius
    --border-width: 2px;
    --radius-small: 6px;
    --radius: 10px;
    --radius-big: 16px;

   

    //Font weight
    //primary font
    --p-light: 300;
    --p-regular: 400;
    --p-medium: 500;
    --p-semi-bold: 600;
    --p-bold: 700;
    --p-extra-bold: 800;
    --p-black: 900;


    //Shadows
    --shadow-primary: 0 0 20px 5px rgba(5,157,255, 0.20);
    --shadow-light: 1px 1px 6px rgba(0, 0, 0, 0.25);
    --shadow-lighter: 4px 4px 20px 20px rgba(0, 0, 0, 0.01);


    //transition easing
    --transition: 0.3s;
    --transition-transform: transform .65s cubic-bezier(.23,1,.32,1);

    //Font Family
    --font-primary: 'TT Lakes Neue Regular', sans-serif;
    --secondary-font: 'Inter', sans-serif;
    --font-awesome: 'Font Awesome 5 Pro';


    
    //Fonts Size
    --font-size-b1: 18px;
    --font-size-b2: 16px;
    --font-size-b3: 14px;
    --font-size-b4: 12px;

    //Line Height
    --line-height-b1: 1.67; //28px
    --line-height-b2: 1.7; //24px
    --line-height-b3: 1.6; //22px
    --line-height-b4: 1.3; //16px

    // Heading Font 
    --h1: 56px; //52px 1.29
    --h2: 44px; //44px 1.23
    --h3: 36px; // 34px 1.14
    --h4: 24px; //30px  1.25
    --h5: 18px; //22px 1.24
    --h6: 16px; //20px 1.25

    --font-tt-lakes-neue-black: 'TT Lakes Neue Black';
    --font-tt-lakes-neue-bold: 'TT Lakes Neue Bold';
    --font-tt-lakes-neue-compressed-black: 'TT Lakes Neue Compressed Black';
    --font-tt-lakes-neue-compressed-bold: 'TT Lakes Neue Compressed Bold';
    --font-tt-lakes-neue-compressed-demibold: 'TT Lakes Neue Compressed DemiBold';
    --font-tt-lakes-neue-compressed-extrabold: 'TT Lakes Neue Compressed ExtraBold';
    --font-tt-lakes-neue-compressed-light: 'TT Lakes Neue Compressed Light';
    --font-tt-lakes-neue-compressed-medium: 'TT Lakes Neue Compressed Medium';
    --font-tt-lakes-neue-compressed-regular: 'TT Lakes Neue Compressed Regular';
    --font-tt-lakes-neue-compressed-thin: 'TT Lakes Neue Compressed Thin';
    --font-tt-lakes-neue-condensed-black: 'TT Lakes Neue Condensed Black';
    --font-tt-lakes-neue-condensed-bold: 'TT Lakes Neue Condensed Bold';
    --font-tt-lakes-neue-condensed-demibold: 'TT Lakes Neue Condensed DemiBold';
    --font-tt-lakes-neue-condensed-extrabold: 'TT Lakes Neue Condensed ExtraBold';
    --font-tt-lakes-neue-condensed-light: 'TT Lakes Neue Condensed Light';
    --font-tt-lakes-neue-condensed-medium: 'TT Lakes Neue Condensed Medium';
    --font-tt-lakes-neue-condensed-regular: 'TT Lakes Neue Condensed Regular';
    --font-tt-lakes-neue-condensed-thin: 'TT Lakes Neue Condensed Thin';
    --font-tt-lakes-neue-extended-black: 'TT Lakes Neue Extended Black';
    --font-tt-lakes-neue-extended-bold: 'TT Lakes Neue Extended Bold';
    --font-tt-lakes-neue-extended-demibold: 'TT Lakes Neue Extended DemiBold';
    --font-tt-lakes-neue-extended-extrabold: 'TT Lakes Neue Extended ExtraBold';
    --font-tt-lakes-neue-extended-light: 'TT Lakes Neue Extended Light';
    --font-tt-lakes-neue-extended-medium: 'TT Lakes Neue Extended Medium';
    --font-tt-lakes-neue-extended-regular: 'TT Lakes Neue Extended Regular';
    --font-tt-lakes-neue-extended-thin: 'TT Lakes Neue Extended Thin';
    --font-tt-lakes-neue-trial-black: 'TT Lakes Neue Black';
    --font-tt-lakes-neue-trial-bold: 'TT Lakes Neue Bold';
    --font-tt-lakes-neue-trial-demibold: 'TT Lakes Neue DemiBold';
    --font-tt-lakes-neue-trial-extrabold: 'TT Lakes Neue ExtraBold';
    --font-tt-lakes-neue-trial-light: 'TT Lakes Neue Light';
    --font-tt-lakes-neue-trial-medium: 'TT Lakes Neue Medium';
    --font-tt-lakes-neue-trial-regular: 'TT Lakes Neue Regular';
    --font-tt-lakes-neue-trial-thin: 'TT Lakes Neue Thin';

    --bg-body: rgba(25, 25, 25, 1);
  --bg-body-gradient: radial-gradient(30% 90% ellipse at bottom center, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 100%);
  --bg-body-hover: rgba(33, 33, 33, 1);
  --bg-button: rgba(255, 255, 255, 0);
  --bg-button-hover: rgba(255, 255, 255, 0.05);
  --bg-button-active: rgba(255, 255, 255, 1.0);
  --bg-mask: rgba(255, 255, 255, 0.5);
  --bg-mask-hover: rgba(255, 255, 255, 1.0);
  --border-button: rgba(255, 255, 255, 0.2);
  --border-button-hover: rgba(255, 255, 255, 1.0);
  --color-button: rgba(255, 255, 255, 0.6);
  --color-button-hover: rgba(255, 255, 255, 1.0);
  --color-button-active: var(--body-bg);
  --font-button: "Varela Round", sans-serif;
  --shadow-button-hover: 0 0 0.3125rem rgba(255, 255, 255, 0.8);
  --transition-easing: cubic-bezier(0.19, 1, 0.22, 1);
}

// Layouts Variation
$smlg-device: 'only screen and (max-width: 1199px)';
$extra-device: 'only screen and (min-width: 1600px) and (max-width: 1919px)';
$laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$lg-layout: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout:'only screen and (min-width: 768px) and (max-width: 991px)';
$sm-layout:'only screen and (max-width: 767px)';
$large-mobile: 'only screen and (max-width: 575px)';
$small-mobile: 'only screen and (max-width: 479px)';

@media (max-width: 768px) {
  .infinite-scroll {
    box-sizing: border-box;
    margin-right: 0 !important;
}

}