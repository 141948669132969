/*--------------------------
    Navigation Styles 
----------------------------*/
.mainmenu-nav {
    .mainmenu {
        display: flex;
        margin: 0;
        padding: 0;
        > li { 
            > a {
                color: var(--color-body);
                font-size: 17px;
                font-weight: 500;
                padding: 0 17px;
                display: block;
                height: 80px;
                line-height: 80px;
                transition: 0.3s;
                &:hover {
                    color: var(--color-primary);
                }
            }
        }
        li {
            margin-top: 0;
            margin-bottom: 0;
            position: relative;
            &.has-droupdown {
                font-family: var(--font-tt-lakes-neue-trial-regular);

                .submenu {
                    min-width: 240px;
                    height: auto;
                    position: absolute;
                    top: 90%;
                    left: 0;
                    z-index: 90;
                    visibility: hidden;
                    text-align: left;
                    padding: 12px 0;
                    transition: 0.3s;
                    border-radius: 0 0 10px 10px;
                    border:1px solid var(--color-primary);
                    background-color: rgba(5, 88, 255, 0.8);

                

                    box-shadow: var(--shadow-lighter);
                    li {
                        a {
                            font-weight: 500;
                            padding: 5px 20px;
                            font-size: 16px;
                            display: block;
                            color: white;
                            margin: 0 10px;
                            border-radius: 3px;
                            @extend %transition;
                            &:hover {
                                color: var(--color-primary);
                                background:white;
                            }
                        }
                    }
                }
    
                &:hover {
                    .submenu {
                        opacity: 1;
                        visibility: visible;
                        top: 100%;
                    }
                }
            }

            &.with-megamenu { 
                position: static;

                .rn-megamenu {
                    position: absolute;
                    transition: 0.3s;
                    top: 90%;
                    width: 100%;
                    left: 0;
                    right: 0;
                    padding: 0 15px;
                    visibility: hidden;
                    opacity: 0;
                    .wrapper {
                        border-radius: 0 0 10px 10px;
                        background-color: var(--color-blackest);
                        box-shadow: var(--shadow-lighter);

                        .mega-menu-item {
                            padding: 22px 10px;
                            border-right: 1px solid var(--color-extra04);
                            height: 100%;

                            li {
                                a {
                                    font-weight: 500;
                                    padding: 5px 14px;
                                    font-size: 15px;
                                    display: block;
                                    color: var(--color-body);
                                    margin: 0 10px;
                                    border-radius: 4px;
                                    transition: 0.3s;
                                    display: flex;
                                    align-items: center;

                                    .rn-badge-card {
                                        margin-left: 10px;
                                    }

                                    &:hover {
                                        color: var(--color-primary);
                                        background: var(--color-darker);
                                    }
                                }
                            }
                        }
                    }
                }

                .single-mega-item {
                    &:last-child {
                        .mega-menu-item {
                            border-right: 0 none;
                        }
                    }
                }

                &:hover {
                    .rn-megamenu {
                        opacity: 1;
                        visibility: visible;
                        top: 100%;
                    }
                }
            }

        }
    }
}

.navwreck{
    color: var(--color-body);
    font-size: 17px;
    font-weight: 500;
    padding: 0 17px;
    display: block;
    height: 80px;
    line-height: 80px;
    transition: 0.3s;
    &:hover {
        color: var(--color-primary);
    }
}

.divra {
    position: relative;
    height: 50px;
    width: 100%;
    font-family: 'Jost', sans-serif;
    font-size: 18px;
    font-weight:300;
    background-color: rgb(255, 255, 255);
    background: #ffffff;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #ffffff, #ffffff);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #ffffff, #ffffff); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    opacity:0.9;
    color:rgba(255,255,255,0.8);
    border: none;
    cursor: pointer;
    clip-path: polygon(15% 0, 100% 0, 100% 100%, 0 100%); /* Sola eğik paralelkenar kesimi */
  
    /* Gerekirse, metin içeriğini ortalamak için aşağıdaki satırları kullanabilirsiniz */
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        font-size: 24px; /* Ikon büyüklüğünü ayarlayabilirsiniz */
        color:rgba(255,255,255,0.8);
        font-weight:300;
        margin-left:7px;
      }
     
  }
  .divra:hover {
    opacity:1;
    background: linear-gradient(to left, #870000 30%, #190A05 70%) right;
    background-size: 200%;
    transition: .5s ease-out;
  }
  
  .divra:hover svg {
    font-size: 24px;
    font-weight: 600;
    margin-left: 12px;
  }
  .vikart{

    display: none;
  }
@media(max-width: 1281px) {
        
    .navwreck{
        color: var(--color-body);
        font-size: 16px;
        font-weight: 500;
        padding: 0px 0px;
        height: auto;
        margin: 0;
        display: block;
        &:hover {
            color: var(--color-primary);
        }
    }
    .vikart{

        display: block;
      }

  }