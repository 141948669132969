

  
  @import "src/assets/scss/default/_variables.scss";

  $red: #000;
  $white: #fff;
  

  .btn {
    color: #fff;
    cursor: pointer;
    // display: block;
    font-size:16px;
    font-weight: 400;
    line-height: 45px;
    padding: 0 0 2em;
    max-width: 180px; 
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%; 
    

    
    @media(min-width: 600px) {
        
      padding: 0 1em 2em;
    

    }
    
    &:hover { text-decoration: none; }
    
  }
  

  .btn-1 {
    background:transparent;
    font-weight: 100;
    
    svg {
      height: 45px;
      left: 0;
      position: absolute;
      top: 0; 
      width: 100%; 
    }
    
    rect {
      fill: none;
      stroke: #fff;
      stroke-width: 2;
      stroke-dasharray: 422, 0;
      transition: all 0.35s linear;
    }
  }
  
  .btn-1:hover {
    background: rgba($red, 0);
    font-weight: 900;
    letter-spacing: 1px;
    
    rect {
      stroke-width: 5;
      stroke-dasharray: 15, 310;
      stroke-dashoffset: 48;
      transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }
  

  .basee-container {
    position: absolute;
    text-align: left;
    margin-bottom: 6px;
    z-index: 1;
    top: 22%;
    left: 15%;
  }

  .ar {
    margin: 0;
    padding: 0;
    font-weight: 300;
    font-size: 18px;
  }
  .rn-header button {
    position: relative;
    height: auto;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: 'Jost', sans-serif;
    font-size: 18px;
    font-weight:700;
background-color: #f9fcff;
background-image: linear-gradient(147deg, #f9fcff 0%, #f9fcff 74%);
    opacity:1;
    color:rgba(0,0,0,0.8);
    border: 1px solid transparent;
    border-radius: 5px;
    cursor: pointer;
  
    /* Gerekirse, metin içeriğini ortalamak için aşağıdaki satırları kullanabilirsiniz */
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        font-size: 24px; /* Ikon büyüklüğünü ayarlayabilirsiniz */
        color:rgba(255,255,255,0.8);
        font-weight:300;
        margin-left:7px;
      }
     
  }
  .rxxxsda {
    position: relative;
    height: auto;
    width: 100%;
    overflow: hidden;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: 'Jost', sans-serif;
    font-size: 18px;
    font-weight:700;
    background-color: #f9fcff;
    background-image: linear-gradient(147deg, #f9fcff 0%, #f9fcff 74%);
    
    opacity:1;
    color:rgba(7, 7, 7, 0.8);
    border: 1px solid transparent;
    border-radius: 5px;
    cursor: pointer;
  margin-left: 5px;
    /* Gerekirse, metin içeriğini ortalamak için aşağıdaki satırları kullanabilirsiniz */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    svg {
        font-size: 24px; /* Ikon büyüklüğünü ayarlayabilirsiniz */
        color:rgba(255,255,255,0.8);
        font-weight:300;
        margin-left:7px;
      }
     
  }


  .rn-header button:hover svg {
    font-size: 24px;
    font-weight: 600;
    margin-left: 12px;
  }
  


 
  /* Bu stil, TEKLİF AL butonunu mobil görünümde düzenlemek içindir */

  @media (max-width: 992px) {
    .rn-header button {
      display:none;
    }
  }

  .bar{
    font-weight: 800;
    font-size: 50px;    
    margin: 0;
    padding: 0;
    color: white;
    max-width: 1000px;
    word-wrap: break-word;
  }

  .btn-cont {
    color: var(--color-body);
    font-size: 17px;
    font-weight: 500;
    display: block;
    height: 80px;
    line-height: 80px;
    transition: 0.3s;

    .btnn {
        position: relative;
        padding: 6px 17px;
    -webkit-font-smoothing: antialiased;
      
      &:hover {
        border: none;
        color: var(--color-body);
   
    
      }
    }
    
    .line-1 {
      content: "";
      display: block;
      position: absolute;
      width: 1px;
      background-color: white;
      left: 0;
      bottom: 0;
    }
    .line-2 {
      content: "";
      display: block;
      position: absolute;
      height: 1px;
      background-color: white;
      left: 0;
      top: 0;
    }
    .line-3 {
      content: "";
      display: block;
      position: absolute;
      width: 1px;
      background-color: white;
      right: 0;
      top: 0;
    }
    .line-4 {
      content: "";
      display: block;
      position: absolute;
      height: 1px;
      background-color: white;
      right: 0;
      bottom: 0;
    }
  }
  
  @keyframes move1 {
    0% {
      height: 100%; 
      bottom: 0;
    }
    54% {
      height: 0; 
      bottom: 100%;
    }
    55% {
      height: 0; 
      bottom: 0;
    }
    100% {
      height: 100%; 
      bottom: 0;
    }
  }
  
  @keyframes move2 {
    0% {
      width: 0;
      left: 0;
    }
    50% {
      width: 100%; 
      left: 0;
    }
    100% {
      width: 0; 
      left: 100%;
    }
  }
  
  @keyframes move3 {
    0% {
      height: 100%; 
      top: 0;
    }
    54% {
      height: 0; 
      top: 100%;
    }
    55% {
      height: 0; 
      top: 0;
    }
    100% {
      height: 100%; 
      top: 0;
    }
  }
  
  @keyframes move4 {
    0% {
      width: 0; 
      right: 0;
    }
    55% {
      width: 100%; 
      right: 0;
    }
    100% {
      width: 0; 
      right: 100%;
    }
  }
  .language-dropdown-container .react-select__control {
    width: 110px;
  }
  
  .language-dropdown-container .react-select__menu {
    position: absolute;
    right: 0;
    width: auto;
  }
  @media (max-width: 1281px) {
 
  .rxxxsda {
    background-color: transparent;
    border: 0 none;
    color: var(--color-white);
    width: 100%;
    font-size: 21px;
    padding: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    cursor: pointer;
}
.language-dropdown-container .react-select__control {
  width: 100% !important;
}
 .react-select-3 {
  position: relative !important;
  right: auto !important;
  width: 100% !important;
  display: none;
}
}