@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900,100i,200i,300i,400i,500i,600i,700i,800i,900i&display=swap'); 
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800,300i,400i,500i,600i,700i,800i&display=swap'); 
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap'); 



@media all{ 
  section { 
    display: block;
  } 

  .elementor-section { 
    position: relative;
 
  } 

  *,:after,:before { 
    box-sizing: border-box;
  } 

  * , .elementor ::after, .elementor ::before { 
    -webkit-box-sizing: border-box; 
    box-sizing: border-box;
  } 

  .elementor *, .elementor ::after, .elementor ::before { 
    -webkit-box-sizing: border-box; 
    box-sizing: border-box;
  } 

.continnerx1122212d{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.continnerx1122212dimggg{
  width: 130px;
  min-width: 130px;
  object-fit: contain;
}

.continnerx1122212dbigheader{
 font-size: 23px;
 color: var(--color-dark);
  margin: 0;
  padding:0;
  font-family: var(--font-tt-lakes-neue-trial-demibold);
  margin-bottom: 5px;
}
.dwsxxasaddawqe
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 1600px;
}
.sdw3eq{
  font-family: var(--font-tt-lakes-neue-extended-demibold);
  font-size: 40px;
  color: var(--color-dark);
  margin-top: 30px;
  margin-left: 100px;
}

.continnerx1122212dbigsubbb{
  font-size: 15px;
  color: black;
   margin: 0;
   padding:0;
   line-height: 18px;
   font-family: var(--font-tt-lakes-neue-trial-regular);
   margin-top: 20px !important;
 }
 .xsdddsaxsaddivdconter{
  margin-left: 15px;
 }
  :selection { 
    background: #444; 
    color: #fff; 
    text-shadow: none;
  } 

  .elementor-container  { 
    display: -webkit-box; 
    display: -ms-flexbox; 
    display: flex; 
    margin-right: auto; 
    margin-left: auto; 
    position: relative;
  } 

  .elementor-section.elementor-section-boxed > .elementor-container  { 
    max-width: 80%;
  } 

  .elementor-row { 
    width: 100%; 
    display: flex;
    flex-wrap: wrap;
  } 
  .elementor-column{
    flex: 1 1 50%;
    box-sizing: border-box;
    padding: 10px;

  }


  @media (max-width: 768px){ 
    .elementor-column { 
        flex: 1 1 100%;
    } 
    .elementor-section.elementor-section-boxed>.elementor-container
{
  max-width: 100%;
}
}   
  

}     
  

@media all{ 
  


  .elementor-column-gap-default > .elementor-row > .elementor-column > .elementor-element-populated  { 
    padding: 10px;
  } 

  .elementor-element.elementor-element-2b4c72e > .elementor-element-populated  { 
    padding-left: 28px;
    padding-right: 28px;
  } 

  .elementor-element.elementor-element-3bd8c06 > .elementor-element-populated  { 
    padding-left: 28px;
    padding-right: 28px;
  } 
  .elementor-element.elementor-element-99295cc > .elementor-element-populated  { 
    padding-left: 28px;
    padding-right: 28px;
  } 

  .elementor-element.elementor-element-6f513df > .elementor-element-populated  { 
    padding-left: 28px;
    padding-right: 28px;
  } 
  .elementor-element.elementor-element-24dba11 > .elementor-element-populated  { 
    padding-left: 28px;
    padding-right: 28px;
  } 

  .elementor-element.elementor-element-020d8f0 > .elementor-element-populated  { 
    padding-left: 28px;
    padding-right: 28px;
  } 
  .elementor-element.elementor-element-f63ea7a > .elementor-element-populated  { 
    padding-left: 28px;
    padding-right: 28px;
  } 

  .elementor-element.elementor-element-9a5ea74 > .elementor-element-populated  { 
    padding-left: 28px;
    padding-right: 28px;
  } 
  .elementor-element.elementor-element-0f37ddf > .elementor-element-populated  { 
    padding-left: 28px;
    padding-right: 28px;
  } 

  .elementor-element.elementor-element-bc90a9e > .elementor-element-populated  { 
    padding-left: 28px;
    padding-right: 28px;
  } 
  
  
  .elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap  { 
    display: -webkit-box; 
    display: -ms-flexbox; 
    display: flex;
  } 

  .elementor-widget { 
    position: relative;
  } 

  .elementor-widget-wrap > .elementor-element  { 
    width: 100%;
  } 

  .elementor-element .elementor-widget-container  { 
    -webkit-transition: background .3s,border .3s,-webkit-border-radius .3s,-webkit-box-shadow .3s; 
    transition: background .3s,border .3s,-webkit-border-radius .3s,-webkit-box-shadow .3s; 
    -o-transition: background .3s,border .3s,border-radius .3s,box-shadow .3s; 
    transition: background .3s,border .3s,border-radius .3s,box-shadow .3s; 
    transition: background .3s,border .3s,border-radius .3s,box-shadow .3s,-webkit-border-radius .3s,-webkit-box-shadow .3s;
  } 






}     



@media all{ 
  .row { 
    display: -webkit-box; 
    display: -ms-flexbox; 
    display: flex; 
    -ms-flex-wrap: wrap; 
    flex-wrap: wrap; 
    margin-right: -15px; 
    margin-left: -15px;
  } 

  .wpdt-c.wpDataTableContainerSimpleTable { 
    margin-top: 0; 
    margin-bottom: 20px; 
    padding: 0;
  } 

  table { 
    border-collapse: collapse;
  } 

  table { 
    border-bottom: 1px solid #e1e6ff; 
    border-collapse: collapse; 
    margin-bottom: 0; 
    border-spacing: 0; 
    width: 100%;
  } 

  .wpdt-c.wpDataTableContainerSimpleTable .wpdtSimpleTable  { 
    display: table; 
    background-color: transparent; 
    border-collapse: collapse; 
    border-spacing: 0; 
    clear: both; 
    margin: 0 auto; 
    width: 100%; 
    table-layout: auto; 
    overflow: auto; 
    text-align: center;
  } 

  .wpdt-c.wpDataTableContainerSimpleTable .wpdtSimpleTable.wpDataTable  { 
    font-size: inherit;
  } 

  td { 
    font-weight: 400; 
    text-align: left; 
    padding: 0 5px;
  } 

  td { 
    color: #5b7d9a; 
    border-top: 1px solid #e1e6ff; 
    border-right: 1px solid #e1e6ff; 
    border-left: 1px solid #e1e6ff; 
    padding: 5px; 
    text-align: center; 
    background: #fff;
  } 

  .wpdt-c.wpDataTableContainerSimpleTable .wpdtSimpleTable.wpDataTable tbody td  { 
    border: 1px solid #ccc; 
    height: 22px; 
    empty-cells: show; 
    line-height: normal; 
    padding: 10px; 
    vertical-align: middle; 
    text-align: center; 
    overflow: hidden; 
    word-break: normal;
  } 
  .wpdt-c{
    color: var(--color-primary);
  }

  .wpdt-c.wpDataTableContainerSimpleTable .wpdtSimpleTable.wpDataTable tbody td  { 
    background-color: #fff;
  } 

  .wpdt-c.wpDataTableContainerSimpleTable .wpdtSimpleTable.wpDataTable tr td:first-child  { 
    border: 1px solid #ccc;
  } 

  .wpdt-c.wpDataTableContainerSimpleTable .wpdtSimpleTable.wpDataTable tr td:last-child  { 
    border: 1px solid #ccc;
  } 
}     

.wpdt-bc-FFFFFF { 
    background-color: #FFFFFF !important;
} 

.wpdt-bc-CCCCCC { 
    background-color: #CCCCCC !important;
} 

@media all{ 
  .wpdt-c.wpDataTableContainerSimpleTable .wpdtSimpleTable td.wpdt-empty-cell  { 
    white-space: pre-line;
  } 
}     

.wpdt-bc-CACACA { 
    background-color: #CACACA !important;
} 

.wpdt-bc-D6D6D6 { 
    background-color: #D6D6D6 !important;
} 

@media all{ 
  .wpdt-c.wpDataTableContainerSimpleTable .wpdtSimpleTable.wpDataTable tbody tr:last-child td  { 
    border-bottom-color: #ccc;
  } 
}     


/* These were inline style tags. Uses id+class to override almost everything */
#wpdtSimpleTable-2.style-E9dFy {  
   border-collapse:collapse;  
   
border-spacing:0px;  
}  
#style-aBqKA.style-aBqKA {  
    width:31.609195402299%;  
                       padding:10px;  
}  
#style-pkBtL.style-pkBtL {  
    width:28.927203065134%;  
                       padding:10px;  
}  
#style-rbXSd.style-rbXSd {  
    width:39.463601532567%;  
                       padding:10px;  
}  
#style-gWYiM.style-gWYiM {  
                       padding:10px;  
}  
#style-bK7aN.style-bK7aN {  
                       padding:10px;  
}  
#style-PGkSs.style-PGkSs {  
                       padding:10px;  
}  
#style-pkdVl.style-pkdVl {  
                       padding:10px;  
}  
#style-JY6aA.style-JY6aA {  
                       padding:10px;  
}  
#style-6zVPn.style-6zVPn {  
                       padding:10px;  
}  
#style-LZMJX.style-LZMJX {  
                       padding:10px;  
}  
#style-1oOWP.style-1oOWP {  
                       padding:10px;  
}  
#style-qQWqm.style-qQWqm {  
                       padding:10px;  
}  
#style-UN8Mm.style-UN8Mm {  
                       padding:10px;  
}  
#style-dJ6N1.style-dJ6N1 {  
                       padding:10px;  
}  
#style-zGvsv.style-zGvsv {  
                       padding:10px;  
}  
#style-ajH95.style-ajH95 {  
                       padding:10px;  
}  
#style-eRrrl.style-eRrrl {  
                       padding:10px;  
}  
#style-zOlHc.style-zOlHc {  
                       padding:10px;  
}  
#style-FKbTH.style-FKbTH {  
                       padding:10px;  
}  
#style-e6Wh5.style-e6Wh5 {  
                       padding:10px;  
}  
#style-gLALx.style-gLALx {  
                       padding:10px;  
}  
#style-KszMl.style-KszMl {  
                       padding:10px;  
}  
#style-hwwTS.style-hwwTS {  
                       padding:10px;  
}  
#style-soyp7.style-soyp7 {  
                       padding:10px;  
}  
#style-U2w5Y.style-U2w5Y {  
                       padding:10px;  
}  
#style-PFqGm.style-PFqGm {  
                       padding:10px;  
}  
#style-5O2hV.style-5O2hV {  
                       padding:10px;  
}  
#style-hh3Fz.style-hh3Fz {  
                       padding:10px;  
}  
#style-oWUol.style-oWUol {  
                       padding:10px;  
}  
#style-Ej8HQ.style-Ej8HQ {  
                       padding:10px;  
}  
#style-cXTGd.style-cXTGd {  
                       padding:10px;  
}  
#style-JBac9.style-JBac9 {  
                       padding:10px;  
}  
#style-FC2BF.style-FC2BF {  
                       padding:10px;  
}  
#style-tBF4q.style-tBF4q {  
                       padding:10px;  
}  
#style-OTqSc.style-OTqSc {  
                       padding:10px;  
}  
#style-NznXk.style-NznXk {  
                       padding:10px;  
}  
#wpdtSimpleTable-3.style-opmh2 {  
   border-collapse:collapse;  
   
border-spacing:0px;  
}  
#style-aEVkD.style-aEVkD {  
    width:31.609195402299%;  
                       padding:10px;  
}  
#style-wY89y.style-wY89y {  
    width:28.927203065134%;  
                       padding:10px;  
}  
#style-dZCY9.style-dZCY9 {  
    width:39.463601532567%;  
                       padding:10px;  
}  
#style-3o1XN.style-3o1XN {  
                       padding:10px;  
}  
#style-WMsCr.style-WMsCr {  
                       padding:10px;  
}  
#style-FZfwd.style-FZfwd {  
                       padding:10px;  
}  
#style-hah7b.style-hah7b {  
                       padding:10px;  
}  
#style-PY9qx.style-PY9qx {  
                       padding:10px;  
}  
#style-g2Rw7.style-g2Rw7 {  
                       padding:10px;  
}  
#style-CvOr6.style-CvOr6 {  
                       padding:10px;  
}  
#style-wH233.style-wH233 {  
                       padding:10px;  
}  
#style-O21iB.style-O21iB {  
                       padding:10px;  
}  
#style-GV2tS.style-GV2tS {  
                       padding:10px;  
}  
#style-Y7EL5.style-Y7EL5 {  
                       padding:10px;  
}  
#style-TIsVI.style-TIsVI {  
                       padding:10px;  
}  
#style-Us1tX.style-Us1tX {  
                       padding:10px;  
}  
#style-9lMvo.style-9lMvo {  
                       padding:10px;  
}  
#style-VsjVf.style-VsjVf {  
                       padding:10px;  
}  
#style-Q1e4r.style-Q1e4r {  
                       padding:10px;  
}  
#style-x2KAL.style-x2KAL {  
                       padding:10px;  
}  
#style-EMP2o.style-EMP2o {  
                       padding:10px;  
}  
#style-a1HAy.style-a1HAy {  
                       padding:10px;  
}  
#style-cTkIo.style-cTkIo {  
                       padding:10px;  
}  
#style-YIYdd.style-YIYdd {  
                       padding:10px;  
}  
#style-VhHxG.style-VhHxG {  
                       padding:10px;  
}  
#style-3qkVg.style-3qkVg {  
                       padding:10px;  
}  
#style-n87KS.style-n87KS {  
                       padding:10px;  
}  
#style-SXNxF.style-SXNxF {  
                       padding:10px;  
}  
#style-rvZ9y.style-rvZ9y {  
                       padding:10px;  
}  
#style-SOV5h.style-SOV5h {  
                       padding:10px;  
}  
#style-oXgBl.style-oXgBl {  
                       padding:10px;  
}  
#style-pMwcU.style-pMwcU {  
                       padding:10px;  
}  
#style-7LQCr.style-7LQCr {  
                       padding:10px;  
}  
#style-YSshU.style-YSshU {  
                       padding:10px;  
}  
#style-Sq9dq.style-Sq9dq {  
                       padding:10px;  
}  
#style-ZsrEe.style-ZsrEe {  
                       padding:10px;  
}  
