

  
  @import "src/assets/scss/default/_variables.scss";

.astralblogmain {
    width: 100%;
    height: auto;
    position: relative;
    background:var(--color-dark);
    padding: 5px;
    padding-top: 40px;
    position: relative;
    z-index:2;
    padding-bottom: 90px;
}
.mozboxwhite{
  color: white;
  font-family: var(--font-tt-lakes-neue-trial-regular);
}
.contentxx{
  color: black !important;
  font-size: 18px;
}
.newakemarg {
   
}
.spaninnerhtmlk{
    font-family: var(--font-tt-lakes-neue-trial-regular) !important;
    font-size: 17px;
    margin-top: 6px;
}
.datespan{
    font-family: var(--font-tt-lakes-neue-compressed-demibold);
    padding: 0;
    margin:0;
}
.rn-blog-area{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
}
.rn-blog-areainner{
    width: 85%;
}

.rnn-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: white;
  background-clip: border-box;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  overflow: hidden;
  transition: border 0.3s ease;


  .inner {
      .thumbnail {
        overflow: hidden;
          a {
              img {
                  width: 100%;
                 padding-top: 10px;
                 padding-left: 10px;
                 padding-right: 10px;
                  transition: all 0.5s; /* Geçiş efekti eklemek */


              }
          }
      }

      .content {
          display: flex;
          flex-wrap: wrap;
          height: 100%;
          padding-top: 5px;
          padding-left: 15px;
          padding-right: 15px;
          font-family: var(--font-tt-lakes-neue-trial-extrabold);
          
          @media #{$sm-layout} {
              padding: 30px 20px;
          }
          .descriptiion {
              margin-bottom: 0;
              margin-bottom: 13px;
          }
          .title {
              font-size: 20px;
              line-height: 1.5;
              transition: all .5s;
              margin-bottom: 0;
              color: var(--color-tertiary-alt);
              margin-top: 5px;
              flex-basis: 100%;
          }
      }

  }

  .thumbnaill {
    position: relative; /* Resmin bulunduğu div'i relative yaparak içindeki elemanları konumlandırabiliriz */
  }
  
  .thumbnaill img {
    width: 100%; /* Resmi div'in genişliğine göre ayarlayın */
    border-radius: 0px; /* Eğer kenar yuvarlaklığı istemiyorsanız, bu değeri sıfıra ayarlayın */
  }
  
  &.card-list-view { 
      height: 100%;
      .inner {
          display: flex;
          height: 100%;

          .thumbnail {
              flex-basis: 50%;
              a {
                  display: block;
                  height: 100%;
                  img {
                      width: auto;
                      object-fit: cover;
                      height: 100%;
                  }
              }
          }
          .content {
              flex-basis: 50%;
              .title {
                  margin-bottom: 10px;
              }
              .descriptiion {
                  margin-bottom: 13px;
                  margin-top: 12px;
              }
          }
      }
  }
}
.grayscale {
    filter: grayscale(100%) brightness(50%) contrast(150%);
}

.rn-meta-list {
  @extend %liststyle;
  font-size: 1.4rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-family: var(--font-tt-lakes-neue-trial-black);

  li {
      margin-top: 0;
      margin-bottom: 0;
      color: var(--color-gray);
      font-size: 14px;
      

      a {
          color: var(--color-gray);
          transition: 0.5s;
      }

      &.separator {
          margin: 0 .8rem;
      }
  }
}

.rnn-meta-list {
    position: absolute; /* Mutlak pozisyon kullanarak, listeyi istediğiniz yere konumlandırabilirsiniz */
    top: 10px; /* Üstten boşluk */
    left: 10px; /* Soldan boşluk */
    list-style: none; /* Liste işaretlerini kaldır */
    margin: 0; /* Varsayılan dış boşlukları kaldır */
    padding: 5px; /* Varsayılan iç boşlukları kaldır */
    background: rgba(0, 0, 0, 0.5); /* Okunabilirlik için hafif bir arka plan rengi ekleyin */
    border-radius: 5px; /* Kenar yuvarlaklığı ekleyin (isteğe bağlı) */
    font-family: var(--font-tt-lakes-neue-trial-black);
  }
  
  .rnn-meta-list li {
    display: inline; /* Liste öğelerini yatay hizaya getir */
  }



.rnn-card:hover {
 

    .inner {
        .thumbnail {
            a {
                img {
                    width: 100%;
                    transform: scale(1.3); /* Üzerine gelindiğinde daha da büyütmek için */

                }
            }
        }



    }

  

}
.blogcontor{
  max-height: 68vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 150px;
  background: white;
}
.vitangri{
    text-transform: uppercase;
    color:var(--color-body);
    font-size: 32px;
    font-family: var(--font-tt-lakes-neue-extended-thin);
}
.imgcolor{
  height: 65vh;
  object-fit: cover;
  width: auto;
}
.imgcolor:hover{
}

.rn-meta-list:hover {
    @extend %liststyle;
    font-size: 1.4rem;
    line-height: 2.2rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
        margin-top: 0;
        margin-bottom: 0;
        color: var(--color-gray);
        font-size: 14px;
        

        a {
            color: var(--color-gray);
            transition: 0.5s;
        }

        &.separator {
            margin: 0 .8rem;
        }
    }
}

@media (max-width: 576px) {
    .blogcontor{
        margin-top: 0px;
    }
    

}


.toggle {
-webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.6);
-moz-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.6);
box-shadow: 0 2px 8px rgba(0, 0, 0, 0.6);
margin: -24px;
position: absolute;
bottom: 11.6%;
left: 50%; }
.toggle.i .cross {
 -webkit-transform: rotateZ(0deg);
 -moz-transform: rotateZ(0deg);
 transform: rotateZ(0deg); }
 .toggle.i .cross .x {
   margin-left: -2px;
   width: 4px;
   margin-top: -12px; }
 .toggle.i .cross .y {
   margin-top: -4px;
   height: 16px; }

.cross {
-webkit-transform: rotateZ(45deg);
-moz-transform: rotateZ(45deg);
transform: rotateZ(45deg);
position: absolute;
left: 50%;
top: 50%; }
.cross .x, .cross .y {
 -webkit-border-radius: 2px;
 -moz-border-radius: 2px;
 border-radius: 2px;
 -webkit-background-clip: padding-box;
 -moz-background-clip: padding-box;
 background-clip: padding-box;
 background-color: #1d1f27;
 height: 4px;
 width: 4px;
 margin: -2px;
 position: absolute;
 left: 0;
 top: 0; }
.cross .x {
 margin-left: -12px;
 margin-top: -2px;
 width: 24px; }
.cross .y {
 margin-top: -12px;
 height: 24px; }

.prompt.hide {
visibility: hidden; }
.prompt.hide .panel {
 -webkit-transform: translate3d(0,250%,0) rotateY(360deg);
 -moz-transform: translate3d(0,250%,0) rotateY(360deg);
 transform: translate3d(0,250%,0) rotateY(360deg);
 opacity: 0; }
.prompt .cell {
-webkit-perspective: 1000px;
-moz-perspective: 1000px;
perspective: 1000px; }
.prompt .dismiss {
-webkit-transform: scale(0.5);
-moz-transform: scale(0.5);
transform: scale(0.5);
background-color: transparent;
position: absolute;
right: 4px;
top: 4px; }
.prompt .x, .prompt .y {
background: white; }
.prompt .center {
max-width: 300px;
width: 82%; }
.prompt .panel {
-webkit-perspective: none;
-moz-perspective: none;
perspective: none;
-webkit-transform-style: flat;
-moz-transform-style: flat;
transform-style: flat;
-webkit-transition: all 1.8s cubic-bezier(0.77, 0, 0.175, 1);
-moz-transition: all 1.8s cubic-bezier(0.77, 0, 0.175, 1);
transition: all 1.8s cubic-bezier(0.77, 0, 0.175, 1);
border: 1px solid rgba(255, 255, 255, 0.15);
background-color: rgba(0, 0, 0, 0.65);
padding: 1.2em 0 1.8em; }
.prompt .panel img {
 margin: 0.6em auto;
 width: 100px; }
.prompt .panel h2 {
 margin: 0 0 0.4em; }
.prompt .panel p {
 font-size: 1.25em;
 margin: 0 10% 1.25em;
 line-height: 1.25em;
 color: #999999; }
.prompt .tilt-scene {
-webkit-perspective: 1000px;
-moz-perspective: 1000px;
perspective: 1000px;
-webkit-transform: translate3d(0,0,100px);
-moz-transform: translate3d(0,0,100px);
transform: translate3d(0,0,100px);
margin: 0 auto;
width: 60%; }
.prompt .tilt {
-webkit-animation: tilt 4s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
-moz-animation: tilt 4s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
animation: tilt 4s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.scene,
.layer {
display: block;
height: 100%;
width: 100%;
padding: 0;
margin: 0; }

.scene {
min-height: 460px;
position: relative;
overflow: hidden; }

.layer {
position: absolute; }
.layer div {
 -webkit-transform: translate3d(0,0,0);
 -moz-transform: translate3d(0,0,0);
 transform: translate3d(0,0,0);
 -webkit-transform-style: preserve-3d;
 -moz-transform-style: preserve-3d;
 transform-style: preserve-3d;
 -webkit-backface-visibility: hidden;
 -moz-backface-visibility: hidden;
 backface-visibility: hidden; }


.background {
background-image: url("https://i.ibb.co/rf8gDkW/sdx.webp");
bottom: 96px;
background-size: cover;
position: absolute;
width: 110%;
left: -5%;
top: -5%; }

.lighthouse {
-webkit-transform-origin: 50% 90%;
-moz-transform-origin: 50% 90%;
transform-origin: 50% 90%;
-webkit-animation: lighthouse 4s 0.1s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
-moz-animation: lighthouse 4s 0.1s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
animation: lighthouse 4s 0.1s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
margin: 0px -64px;
background-size: 128px 224px;
height: 224px;
width: 128px;
bottom: 64px;
position: absolute;
right: 20%; }

.wave {
background: transparent repeat-x;
position: absolute;
width: 300%;
left: -100%; }
.wave:after {
 background-color: black;
 position: absolute;
 display: block;
 content: "";
 height: 500px;
 width: 100%;
 top: 100%;
 left: 0; }
.wave.plain {
 background-image: url(https://i.ibb.co/tBpCBnd/wave-paintnew.png); }
.wave.paint {
 background-image: url(https://i.ibb.co/tBpCBnd/wave-paintnew.png); }

.light {
margin: -120px -120px;
background-size: 240px 240px;
height: 240px;
width: 240px;
background-repeat: no-repeat;
bottom: 112px;
position: absolute;
left: 50%; }
.light.orange {
 background-image: url(https://wagerfield.github.io/parallax/assets/images/light-orange.png); }
.light.purple {
 background-image: url(https://wagerfield.github.io/parallax/assets/images/light-purple.png); }
.light.a {
 bottom: 80px;
 left: 20%; }
.light.b {
 bottom: 80px;
 left: 30%; }
.light.c {
 bottom: 112px;
 left: 45%; }
.light.d {
 bottom: 96px;
 left: 60%; }
.light.e {
 bottom: 112px;
 left: 75%; }
.light.f {
 bottom: 64px;
 left: 80%; }
.light.phase-1 {
 -webkit-animation: phase 20s 0.1s infinite linear;
 -moz-animation: phase 20s 0.1s infinite linear;
 animation: phase 20s 0.1s infinite linear; }
.light.phase-2 {
 -webkit-animation: phase 18s 0.1s infinite linear;
 -moz-animation: phase 18s 0.1s infinite linear;
 animation: phase 18s 0.1s infinite linear; }
.light.phase-3 {
 -webkit-animation: phase 16s 0.1s infinite linear;
 -moz-animation: phase 16s 0.1s infinite linear;
 animation: phase 16s 0.1s infinite linear; }
.light.phase-4 {
 -webkit-animation: phase 14s 0.1s infinite linear;
 -moz-animation: phase 14s 0.1s infinite linear;
 animation: phase 14s 0.1s infinite linear; }
.light.phase-5 {
 -webkit-animation: phase 12s 0.1s infinite linear;
 -moz-animation: phase 12s 0.1s infinite linear;
 animation: phase 12s 0.1s infinite linear; }
.light.phase-6 {
 -webkit-animation: phase 10s 0.1s infinite linear;
 -moz-animation: phase 10s 0.1s infinite linear;
 animation: phase 10s 0.1s infinite linear; }

.rope {
width: 120%;
position: relative;
left: -10%;
position: relative; }

.hanger {
-webkit-transform-origin: 0% 0%;
-moz-transform-origin: 0% 0%;
transform-origin: 0% 0%;
position: absolute; }
.hanger.position-1 {
 top: 28%; }
.hanger.position-2 {
 top: 46%; }
.hanger.position-3 {
 top: 59%; }
.hanger.position-4 {
 top: 66.5%; }
.hanger.position-5 {
 top: 69.5%; }
.hanger.position-6 {
 top: 66.5%; }
.hanger.position-7 {
 top: 59%; }
.hanger.position-8 {
 top: 46%; }
.hanger.position-9 {
 top: 28%; }
.hanger.position-1 {
 left: 10%; }
.hanger.position-2 {
 left: 20%; }
.hanger.position-3 {
 left: 30%; }
.hanger.position-4 {
 left: 40%; }
.hanger.position-5 {
 left: 50%; }
.hanger.position-6 {
 left: 60%; }
.hanger.position-7 {
 left: 70%; }
.hanger.position-8 {
 left: 80%; }
.hanger.position-9 {
 left: 90%; }

.board {
-webkit-transform-origin: 50% 0%;
-moz-transform-origin: 50% 0%;
transform-origin: 50% 0%;
margin: 0px -140px;
background-size: 280px 280px;
height: 280px;
width: 280px;
background-repeat: no-repeat;
position: absolute;
top: -4px;
left: 0; }
.board.birds {
  }
.board.cloud-1 {
  }
.board.cloud-2 {
   }
.board.cloud-3 {
}
.board.cloud-4 {
 }


.swing-1 {
-webkit-animation: swing 4s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
-moz-animation: swing 4s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
animation: swing 4s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.swing-2 {
-webkit-animation: swing 3.5s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
-moz-animation: swing 3.5s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
animation: swing 3.5s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.swing-3 {
-webkit-animation: swing 3s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
-moz-animation: swing 3s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
animation: swing 3s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.swing-4 {
-webkit-animation: swing 2.5s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
-moz-animation: swing 2.5s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
animation: swing 2.5s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.swing-5 {
-webkit-animation: swing 2s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
-moz-animation: swing 2s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
animation: swing 2s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.wave.depth-10 {
-webkit-animation: wave 8s 0.1s infinite linear;
-moz-animation: wave 8s 0.1s infinite linear;
animation: wave 8s 0.1s infinite linear;
bottom: 140px;
margin: 0px 0px;
background-size: 40px 16px;
height: 16px; }
.wave.depth-10:after {
 height: 144px; }

.rope.depth-10 {
-webkit-transform: scale(1);
-moz-transform: scale(1);
transform: scale(1);
top: 20%; }
.rope.depth-10 .hanger {
 -webkit-transform: scale(0.25);
 -moz-transform: scale(0.25);
 transform: scale(0.25); }

.wave.depth-20 {
-webkit-animation: wave 7.55556s 0.1s infinite linear;
-moz-animation: wave 7.55556s 0.1s infinite linear;
animation: wave 7.55556s 0.1s infinite linear;
bottom: 120px;
margin: 0px 0px;
background-size: 80px 32px;
height: 32px; }
.wave.depth-20:after {
 height: 128px; }

.rope.depth-20 {
-webkit-transform: scale(1.02778);
-moz-transform: scale(1.02778);
transform: scale(1.02778);
top: 16.66667%; }
.rope.depth-20 .hanger {
 -webkit-transform: scale(0.27778);
 -moz-transform: scale(0.27778);
 transform: scale(0.27778); }

.wave.depth-30 {
-webkit-animation: wave 7.11111s 0.1s infinite linear;
-moz-animation: wave 7.11111s 0.1s infinite linear;
animation: wave 7.11111s 0.1s infinite linear;
bottom: 100px;
margin: 0px 0px;
background-size: 120px 48px;
height: 48px; }
.wave.depth-30:after {
 height: 112px; }

.rope.depth-30 {
-webkit-transform: scale(1.05556);
-moz-transform: scale(1.05556);
transform: scale(1.05556);
top: 13.33333%; }
.rope.depth-30 .hanger {
 -webkit-transform: scale(0.30556);
 -moz-transform: scale(0.30556);
 transform: scale(0.30556); }

.wave.depth-40 {
-webkit-animation: wave 6.66667s 0.1s infinite linear;
-moz-animation: wave 6.66667s 0.1s infinite linear;
animation: wave 6.66667s 0.1s infinite linear;
bottom: 80px;
margin: 0px 0px;
background-size: 160px 64px;
height: 64px; }
.wave.depth-40:after {
 height: 96px; }

.rope.depth-40 {
-webkit-transform: scale(1.08333);
-moz-transform: scale(1.08333);
transform: scale(1.08333);
top: 10.0%; }
.rope.depth-40 .hanger {
 -webkit-transform: scale(0.33333);
 -moz-transform: scale(0.33333);
 transform: scale(0.33333); }

.wave.depth-50 {
-webkit-animation: wave 6.22222s 0.1s infinite linear;
-moz-animation: wave 6.22222s 0.1s infinite linear;
animation: wave 6.22222s 0.1s infinite linear;
bottom: 60px;
margin: 0px 0px;
background-size: 200px 80px;
height: 80px; }
.wave.depth-50:after {
 height: 80px; }

.rope.depth-50 {
-webkit-transform: scale(1.11111);
-moz-transform: scale(1.11111);
transform: scale(1.11111);
top: 6.66667%; }
.rope.depth-50 .hanger {
 -webkit-transform: scale(0.36111);
 -moz-transform: scale(0.36111);
 transform: scale(0.36111); }

.wave.depth-60 {
-webkit-animation: wave 5.77778s 0.1s infinite linear;
-moz-animation: wave 5.77778s 0.1s infinite linear;
animation: wave 5.77778s 0.1s infinite linear;
bottom: 40px;
margin: 0px 0px;
background-size: 240px 96px;
height: 96px; }
.wave.depth-60:after {
 height: 64px; }

.rope.depth-60 {
-webkit-transform: scale(1.13889);
-moz-transform: scale(1.13889);
transform: scale(1.13889);
top: 3.33333%; }
.rope.depth-60 .hanger {
 -webkit-transform: scale(0.38889);
 -moz-transform: scale(0.38889);
 transform: scale(0.38889); }

.wave.depth-70 {
-webkit-animation: wave 5.33333s 0.1s infinite linear;
-moz-animation: wave 5.33333s 0.1s infinite linear;
animation: wave 5.33333s 0.1s infinite linear;
bottom: 20px;
margin: 0px 0px;
background-size: 280px 112px;
height: 112px; }
.wave.depth-70:after {
 height: 48px; }

.rope.depth-70 {
-webkit-transform: scale(1.16667);
-moz-transform: scale(1.16667);
transform: scale(1.16667);
top: 0.0%; }
.rope.depth-70 .hanger {
 -webkit-transform: scale(0.41667);
 -moz-transform: scale(0.41667);
 transform: scale(0.41667); }

.wave.depth-80 {
-webkit-animation: wave 4.88889s 0.1s infinite linear;
-moz-animation: wave 4.88889s 0.1s infinite linear;
animation: wave 4.88889s 0.1s infinite linear;
bottom: 0px;
margin: 0px 0px;
background-size: 320px 128px;
height: 128px; }
.wave.depth-80:after {
 height: 32px; }

.rope.depth-80 {
-webkit-transform: scale(1.19444);
-moz-transform: scale(1.19444);
transform: scale(1.19444);
top: -3.33333%; }
.rope.depth-80 .hanger {
 -webkit-transform: scale(0.44444);
 -moz-transform: scale(0.44444);
 transform: scale(0.44444); }

.wave.depth-90 {
-webkit-animation: wave 4.44444s 0.1s infinite linear;
-moz-animation: wave 4.44444s 0.1s infinite linear;
animation: wave 4.44444s 0.1s infinite linear;
bottom: -20px;
margin: 0px 0px;
background-size: 360px 144px;
height: 144px; }
.wave.depth-90:after {
 height: 16px; }

.rope.depth-90 {
-webkit-transform: scale(1.22222);
-moz-transform: scale(1.22222);
transform: scale(1.22222);
top: -6.66667%; }
.rope.depth-90 .hanger {
 -webkit-transform: scale(0.47222);
 -moz-transform: scale(0.47222);
 transform: scale(0.47222); }

.wave.depth-100 {
-webkit-animation: wave 4s 0.1s infinite linear;
-moz-animation: wave 4s 0.1s infinite linear;
animation: wave 4s 0.1s infinite linear;
bottom: -40px;
margin: 0px 0px;
background-size: 400px 160px;
height: 160px; }
.wave.depth-100:after {
 height: 0px; }

.rope.depth-100 {
-webkit-transform: scale(1.25);
-moz-transform: scale(1.25);
transform: scale(1.25);
top: -10%; }
.rope.depth-100 .hanger {
 -webkit-transform: scale(0.5);
 -moz-transform: scale(0.5);
 transform: scale(0.5); }

@media all and (min-width: 400px) {
html {
 font-size: 12px; }

.wrapper {
 min-height: 360px; }

.about .panel header:before {
 background-size: 66px 90px; }

.toggle {
 bottom: 12%; }

.prompt .panel img {
 width: 80px; }

.scene {
 min-height: 360px; }

.rope.depth-10 {
 top: 10%; }
 .rope.depth-10 .hanger {
   -webkit-transform: scale(0.3);
   -moz-transform: scale(0.3);
   transform: scale(0.3); }

.rope.depth-20 {
 top: 6.11111%; }
 .rope.depth-20 .hanger {
   -webkit-transform: scale(0.33333);
   -moz-transform: scale(0.33333);
   transform: scale(0.33333); }

.rope.depth-30 {
 top: 2.22222%; }
 .rope.depth-30 .hanger {
   -webkit-transform: scale(0.36667);
   -moz-transform: scale(0.36667);
   transform: scale(0.36667); }

.rope.depth-40 {
 top: -1.66667%; }
 .rope.depth-40 .hanger {
   -webkit-transform: scale(0.4);
   -moz-transform: scale(0.4);
   transform: scale(0.4); }

.rope.depth-50 {
 top: -5.55556%; }
 .rope.depth-50 .hanger {
   -webkit-transform: scale(0.43333);
   -moz-transform: scale(0.43333);
   transform: scale(0.43333); }

.rope.depth-60 {
 top: -9.44444%; }
 .rope.depth-60 .hanger {
   -webkit-transform: scale(0.46667);
   -moz-transform: scale(0.46667);
   transform: scale(0.46667); }

.rope.depth-70 {
 top: -13.33333%; }
 .rope.depth-70 .hanger {
   -webkit-transform: scale(0.5);
   -moz-transform: scale(0.5);
   transform: scale(0.5); }

.rope.depth-80 {
 top: -17.22222%; }
 .rope.depth-80 .hanger {
   -webkit-transform: scale(0.53333);
   -moz-transform: scale(0.53333);
   transform: scale(0.53333); }

.rope.depth-90 {
 top: -21.11111%; }
 .rope.depth-90 .hanger {
   -webkit-transform: scale(0.56667);
   -moz-transform: scale(0.56667);
   transform: scale(0.56667); }

.rope.depth-100 {
 top: -25%; }
 .rope.depth-100 .hanger {
   -webkit-transform: scale(0.6);
   -moz-transform: scale(0.6);
   transform: scale(0.6); } }
@media all and (min-width: 600px) {
html {
 font-size: 15px; }

button {
 -webkit-border-radius: 28px;
 -moz-border-radius: 28px;
 border-radius: 28px;
 -webkit-background-clip: padding-box;
 -moz-background-clip: padding-box;
 background-clip: padding-box;
 height: 56px;
 width: 56px; }

footer .fb-like {
 position: static;
 bottom: auto;
 right: auto; }

.center {
 max-width: 440px; }

.about .panel:after {
 background-size: 252px 252px;
 height: 180px;
 width: 140px; }
.about .panel header:before {
 background-size: 82.5px 112.5px; }

.prompt {
 overflow: visible;
 display: block;
 width: 0; }
 .prompt.hide .panel {
   -webkit-transform: translate3d(0,50%,400px) rotateX(90deg);
   -moz-transform: translate3d(0,50%,400px) rotateX(90deg);
   transform: translate3d(0,50%,400px) rotateX(90deg); }
 .prompt .cell {
   position: absolute;
   display: block;
   bottom: 25px;
   left: 25px; }
 .prompt .dismiss {
   right: 0px;
   top: 0px; }
 .prompt .panel {
   -webkit-transition: all 1.2s cubic-bezier(0.77, 0, 0.175, 1);
   -moz-transition: all 1.2s cubic-bezier(0.77, 0, 0.175, 1);
   transition: all 1.2s cubic-bezier(0.77, 0, 0.175, 1);
   -webkit-transform-origin: 50% 100%;
   -moz-transform-origin: 50% 100%;
   transform-origin: 50% 100%;
   padding: 1em 0 0.8em;
   width: 220px; }
   .prompt .panel img {
     margin: 0.4em auto 0.6em;
     width: 80px; }
   .prompt .panel h2 {
     font-size: 1.6em; }
   .prompt .panel p {
     font-size: 0.93333em;
     margin: 0 10% 1.3em;
     line-height: 1.3em; }

.toggle {
 margin: -28px;
 bottom: 15%; }

.title {
 font-size: 3.46667em; }

.background {
 bottom: 96px; }

.lighthouse {
 margin: 0px -96px;
 background-size: 192px 336px;
 height: 336px;
 width: 192px;
 bottom: 128px; }

.light {
 margin: -140px -140px;
 background-size: 280px 280px;
 height: 280px;
 width: 280px; }
 .light.a {
   bottom: 144px; }
 .light.b {
   bottom: 144px; }
 .light.c {
   bottom: 192px; }
 .light.d {
   bottom: 160px; }
 .light.e {
   bottom: 192px; }
 .light.f {
   bottom: 128px; }

.wave.depth-10 {
 bottom: 210px;
 margin: 0px 0px;
 background-size: 60px 24px;
 height: 24px; }
 .wave.depth-10:after {
   height: 216px; }

.rope.depth-10 {
 top: 20%; }
 .rope.depth-10 .hanger {
   -webkit-transform: scale(0.5);
   -moz-transform: scale(0.5);
   transform: scale(0.5); }

.wave.depth-20 {
 bottom: 180px;
 margin: 0px 0px;
 background-size: 120px 48px;
 height: 48px; }
 .wave.depth-20:after {
   height: 192px; }

.rope.depth-20 {
 top: 16.66667%; }
 .rope.depth-20 .hanger {
   -webkit-transform: scale(0.55556);
   -moz-transform: scale(0.55556);
   transform: scale(0.55556); }

.wave.depth-30 {
 bottom: 150px;
 margin: 0px 0px;
 background-size: 180px 72px;
 height: 72px; }
 .wave.depth-30:after {
   height: 168px; }

.rope.depth-30 {
 top: 13.33333%; }
 .rope.depth-30 .hanger {
   -webkit-transform: scale(0.61111);
   -moz-transform: scale(0.61111);
   transform: scale(0.61111); }

.wave.depth-40 {
 bottom: 120px;
 margin: 0px 0px;
 background-size: 240px 96px;
 height: 96px; }
 .wave.depth-40:after {
   height: 144px; }

.rope.depth-40 {
 top: 10.0%; }
 .rope.depth-40 .hanger {
   -webkit-transform: scale(0.66667);
   -moz-transform: scale(0.66667);
   transform: scale(0.66667); }

.wave.depth-50 {
 bottom: 90px;
 margin: 0px 0px;
 background-size: 300px 120px;
 height: 120px; }
 .wave.depth-50:after {
   height: 120px; }

.rope.depth-50 {
 top: 6.66667%; }
 .rope.depth-50 .hanger {
   -webkit-transform: scale(0.72222);
   -moz-transform: scale(0.72222);
   transform: scale(0.72222); }

.wave.depth-60 {
 bottom: 60px;
 margin: 0px 0px;
 background-size: 360px 144px;
 height: 144px; }
 .wave.depth-60:after {
   height: 96px; }

.rope.depth-60 {
 top: 3.33333%; }
 .rope.depth-60 .hanger {
   -webkit-transform: scale(0.77778);
   -moz-transform: scale(0.77778);
   transform: scale(0.77778); }

.wave.depth-70 {
 bottom: 30px;
 margin: 0px 0px;
 background-size: 420px 168px;
 height: 168px; }
 .wave.depth-70:after {
   height: 72px; }

.rope.depth-70 {
 top: 0.0%; }
 .rope.depth-70 .hanger {
   -webkit-transform: scale(0.83333);
   -moz-transform: scale(0.83333);
   transform: scale(0.83333); }

.wave.depth-80 {
 bottom: 0px;
 margin: 0px 0px;
 background-size: 480px 192px;
 height: 192px; }
 .wave.depth-80:after {
   height: 48px; }

.rope.depth-80 {
 top: -3.33333%; }
 .rope.depth-80 .hanger {
   -webkit-transform: scale(0.88889);
   -moz-transform: scale(0.88889);
   transform: scale(0.88889); }

.wave.depth-90 {
 bottom: -30px;
 margin: 0px 0px;
 background-size: 540px 216px;
 height: 216px; }
 .wave.depth-90:after {
   height: 24px; }

.rope.depth-90 {
 top: -6.66667%; }
 .rope.depth-90 .hanger {
   -webkit-transform: scale(0.94444);
   -moz-transform: scale(0.94444);
   transform: scale(0.94444); }

.wave.depth-100 {
 bottom: -60px;
 margin: 0px 0px;
 background-size: 600px 240px;
 height: 240px; }
 .wave.depth-100:after {
   height: 0px; }

.rope.depth-100 {
 top: -10%; }
 .rope.depth-100 .hanger {
   -webkit-transform: scale(1);
   -moz-transform: scale(1);
   transform: scale(1); } }
@media all and (min-width: 900px) {
html {
 font-size: 15px; }

.center {
 max-width: 560px; }

.about .panel:after {
 background-size: 280px 280px;
 height: 200px;
 width: 160px; }
.about .panel header:before {
 background-size: 82.5px 112.5px; }
.about .panel .links a {
 padding: 1.2em 0 1.3em; }

.toggle {
 margin: -28px;
 bottom: 12%; }

.title {
 font-size: 3.46667em; }

.background {
 bottom: 160px; }

.lighthouse {
 margin: 0px -104px;
 background-size: 208px 364px;
 height: 364px;
 width: 208px;
 bottom: 128px; }

.light {
 margin: -160px -160px;
 background-size: 320px 320px;
 height: 320px;
 width: 320px; }
 .light.a {
   bottom: 112px;
   left: 15%; }
 .light.b {
   bottom: 160px; }
 .light.c {
   bottom: 208px; }
 .light.d {
   bottom: 176px; }
 .light.e {
   bottom: 208px; }
 .light.f {
   bottom: 128px;
   left: 85%; }

.wave.depth-10 {
 bottom: 238px;
 margin: 0px 0px;
 background-size: 68px 27.2px;
 height: 27px; }
 .wave.depth-10:after {
   height: 244.8px; }

.rope.depth-10 {
 top: 10%; }
 .rope.depth-10 .hanger {
   -webkit-transform: scale(0.5);
   -moz-transform: scale(0.5);
   transform: scale(0.5); }

.wave.depth-20 {
 bottom: 204px;
 margin: 0px 0px;
 background-size: 136px 54.4px;
 height: 54px; }
 .wave.depth-20:after {
   height: 217.6px; }

.rope.depth-20 {
 top: 6.66667%; }
 .rope.depth-20 .hanger {
   -webkit-transform: scale(0.56111);
   -moz-transform: scale(0.56111);
   transform: scale(0.56111); }

.wave.depth-30 {
 bottom: 170px;
 margin: 0px 0px;
 background-size: 204px 81.6px;
 height: 82px; }
 .wave.depth-30:after {
   height: 190.4px; }

.rope.depth-30 {
 top: 3.33333%; }
 .rope.depth-30 .hanger {
   -webkit-transform: scale(0.62222);
   -moz-transform: scale(0.62222);
   transform: scale(0.62222); }

.wave.depth-40 {
 bottom: 136px;
 margin: 0px 0px;
 background-size: 272px 108.8px;
 height: 109px; }
 .wave.depth-40:after {
   height: 163.2px; }

.rope.depth-40 {
 top: 0.0%; }
 .rope.depth-40 .hanger {
   -webkit-transform: scale(0.68333);
   -moz-transform: scale(0.68333);
   transform: scale(0.68333); }

.wave.depth-50 {
 bottom: 102px;
 margin: 0px 0px;
 background-size: 340px 136px;
 height: 136px; }
 .wave.depth-50:after {
   height: 136px; }

.rope.depth-50 {
 top: -3.33333%; }
 .rope.depth-50 .hanger {
   -webkit-transform: scale(0.74444);
   -moz-transform: scale(0.74444);
   transform: scale(0.74444); }

.wave.depth-60 {
 bottom: 68px;
 margin: 0px 0px;
 background-size: 408px 163.2px;
 height: 163px; }
 .wave.depth-60:after {
   height: 108.8px; }

.rope.depth-60 {
 top: -6.66667%; }
 .rope.depth-60 .hanger {
   -webkit-transform: scale(0.80556);
   -moz-transform: scale(0.80556);
   transform: scale(0.80556); }

.wave.depth-70 {
 bottom: 34px;
 margin: 0px 0px;
 background-size: 476.0px 190.4px;
 height: 190px; }
 .wave.depth-70:after {
   height: 81.6px; }

.rope.depth-70 {
 top: -10.0%; }
 .rope.depth-70 .hanger {
   -webkit-transform: scale(0.86667);
   -moz-transform: scale(0.86667);
   transform: scale(0.86667); }

.wave.depth-80 {
 bottom: 0px;
 margin: 0px 0px;
 background-size: 544px 217.6px;
 height: 218px; }
 .wave.depth-80:after {
   height: 54.4px; }

.rope.depth-80 {
 top: -13.33333%; }
 .rope.depth-80 .hanger {
   -webkit-transform: scale(0.92778);
   -moz-transform: scale(0.92778);
   transform: scale(0.92778); }

.wave.depth-90 {
 bottom: -34px;
 margin: 0px 0px;
 background-size: 612px 244.8px;
 height: 245px; }
 .wave.depth-90:after {
   height: 27.2px; }

.rope.depth-90 {
 top: -16.66667%; }
 .rope.depth-90 .hanger {
   -webkit-transform: scale(0.98889);
   -moz-transform: scale(0.98889);
   transform: scale(0.98889); }

.wave.depth-100 {
 bottom: -68px;
 margin: 0px 0px;
 background-size: 680px 272px;
 height: 272px; }
 .wave.depth-100:after {
   height: 0px; }

.rope.depth-100 {
 top: -20%; }
 .rope.depth-100 .hanger {
   -webkit-transform: scale(1.05);
   -moz-transform: scale(1.05);
   transform: scale(1.05); } }
@media all and (min-width: 1200px) {
html {
 font-size: 16px; }

.center {
 max-width: 620px; }

.about .panel:after {
 background-size: 280px 280px;
 height: 200px;
 width: 170px; }
.about .panel header:before {
 background-size: 93.5px 127.5px; }

.prompt .panel {
 width: 240px; }

.lighthouse {
 margin: 0px -112px;
 background-size: 224px 392px;
 height: 392px;
 width: 224px; }

.light {
 margin: -200px -200px;
 background-size: 400px 400px;
 height: 400px;
 width: 400px; }

.wave.depth-10 {
 bottom: 252px;
 margin: 0px 0px;
 background-size: 72px 28.8px;
 height: 29px; }
 .wave.depth-10:after {
   height: 259.2px; }

.rope.depth-10 {
 top: 5%; }
 .rope.depth-10 .hanger {
   -webkit-transform: scale(0.6);
   -moz-transform: scale(0.6);
   transform: scale(0.6); }

.wave.depth-20 {
 bottom: 216px;
 margin: 0px 0px;
 background-size: 144px 57.6px;
 height: 58px; }
 .wave.depth-20:after {
   height: 230.4px; }

.rope.depth-20 {
 top: 1.66667%; }
 .rope.depth-20 .hanger {
   -webkit-transform: scale(0.67778);
   -moz-transform: scale(0.67778);
   transform: scale(0.67778); }

.wave.depth-30 {
 bottom: 180px;
 margin: 0px 0px;
 background-size: 216px 86.4px;
 height: 86px; }
 .wave.depth-30:after {
   height: 201.6px; }

.rope.depth-30 {
 top: -1.66667%; }
 .rope.depth-30 .hanger {
   -webkit-transform: scale(0.75556);
   -moz-transform: scale(0.75556);
   transform: scale(0.75556); }

.wave.depth-40 {
 bottom: 144px;
 margin: 0px 0px;
 background-size: 288px 115.2px;
 height: 115px; }
 .wave.depth-40:after {
   height: 172.8px; }

.rope.depth-40 {
 top: -5.0%; }
 .rope.depth-40 .hanger {
   -webkit-transform: scale(0.83333);
   -moz-transform: scale(0.83333);
   transform: scale(0.83333); }

.wave.depth-50 {
 bottom: 108px;
 margin: 0px 0px;
 background-size: 360px 144px;
 height: 144px; }
 .wave.depth-50:after {
   height: 144px; }

.rope.depth-50 {
 top: -8.33333%; }
 .rope.depth-50 .hanger {
   -webkit-transform: scale(0.91111);
   -moz-transform: scale(0.91111);
   transform: scale(0.91111); }

.wave.depth-60 {
 bottom: 72px;
 margin: 0px 0px;
 background-size: 432px 172.8px;
 height: 173px; }
 .wave.depth-60:after {
   height: 115.2px; }

.rope.depth-60 {
 top: -11.66667%; }
 .rope.depth-60 .hanger {
   -webkit-transform: scale(0.98889);
   -moz-transform: scale(0.98889);
   transform: scale(0.98889); }

.wave.depth-70 {
 bottom: 36px;
 margin: 0px 0px;
 background-size: 504.0px 201.6px;
 height: 202px; }
 .wave.depth-70:after {
   height: 86.4px; }

.rope.depth-70 {
 top: -15.0%; }
 .rope.depth-70 .hanger {
   -webkit-transform: scale(1.06667);
   -moz-transform: scale(1.06667);
   transform: scale(1.06667); }

.wave.depth-80 {
 bottom: 0px;
 margin: 0px 0px;
 background-size: 576px 230.4px;
 height: 230px; }
 .wave.depth-80:after {
   height: 57.6px; }

.rope.depth-80 {
 top: -18.33333%; }
 .rope.depth-80 .hanger {
   -webkit-transform: scale(1.14444);
   -moz-transform: scale(1.14444);
   transform: scale(1.14444); }

.wave.depth-90 {
 bottom: -36px;
 margin: 0px 0px;
 background-size: 648px 259.2px;
 height: 259px; }
 .wave.depth-90:after {
   height: 28.8px; }

.rope.depth-90 {
 top: -21.66667%; }
 .rope.depth-90 .hanger {
   -webkit-transform: scale(1.22222);
   -moz-transform: scale(1.22222);
   transform: scale(1.22222); }

.wave.depth-100 {
 bottom: -72px;
 margin: 0px 0px;
 background-size: 720px 288px;
 height: 288px; }
 .wave.depth-100:after {
   height: 0px; }

.rope.depth-100 {
 top: -25%; }
 .rope.depth-100 .hanger {
   -webkit-transform: scale(1.3);
   -moz-transform: scale(1.3);
   transform: scale(1.3); } }
a, button {
-webkit-transition: color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), background-color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
-moz-transition: color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), background-color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
transition: color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), background-color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.6s cubic-bezier(0.165, 0.84, 0.44, 1); }

.cross, .x, .y {
-webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
-moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }

.about .panel,
.about .cables {
-webkit-transition: all 0.8s cubic-bezier(0.77, 0, 0.175, 1);
-moz-transition: all 0.8s cubic-bezier(0.77, 0, 0.175, 1);
transition: all 0.8s cubic-bezier(0.77, 0, 0.175, 1); }

@-webkit-keyframes phase {
0% {
 opacity: 1; }

25% {
 opacity: 0.4; }

50% {
 opacity: 0.8; }

75% {
 opacity: 0.4; }

100% {
 opacity: 1; } }

@-moz-keyframes phase {
0% {
 opacity: 1; }

25% {
 opacity: 0.4; }

50% {
 opacity: 0.8; }

75% {
 opacity: 0.4; }

100% {
 opacity: 1; } }

@-ms-keyframes phase {
0% {
 opacity: 1; }

25% {
 opacity: 0.4; }

50% {
 opacity: 0.8; }

75% {
 opacity: 0.4; }

100% {
 opacity: 1; } }

@keyframes phase {
0% {
 opacity: 1; }

25% {
 opacity: 0.4; }

50% {
 opacity: 0.8; }

75% {
 opacity: 0.4; }

100% {
 opacity: 1; } }

@-webkit-keyframes tilt {
0% {
 -webkit-transform: rotateX(-30deg);
 -moz-transform: rotateX(-30deg);
 transform: rotateX(-30deg); }

25% {
 -webkit-transform: rotateX( 30deg);
 -moz-transform: rotateX( 30deg);
 transform: rotateX( 30deg); }

50% {
 -webkit-transform: rotateY(-30deg);
 -moz-transform: rotateY(-30deg);
 transform: rotateY(-30deg); }

75% {
 -webkit-transform: rotateY( 30deg);
 -moz-transform: rotateY( 30deg);
 transform: rotateY( 30deg); }

100% {
 -webkit-transform: rotateZ( 20deg);
 -moz-transform: rotateZ( 20deg);
 transform: rotateZ( 20deg); } }

@-moz-keyframes tilt {
0% {
 -webkit-transform: rotateX(-30deg);
 -moz-transform: rotateX(-30deg);
 transform: rotateX(-30deg); }

25% {
 -webkit-transform: rotateX( 30deg);
 -moz-transform: rotateX( 30deg);
 transform: rotateX( 30deg); }

50% {
 -webkit-transform: rotateY(-30deg);
 -moz-transform: rotateY(-30deg);
 transform: rotateY(-30deg); }

75% {
 -webkit-transform: rotateY( 30deg);
 -moz-transform: rotateY( 30deg);
 transform: rotateY( 30deg); }

100% {
 -webkit-transform: rotateZ( 20deg);
 -moz-transform: rotateZ( 20deg);
 transform: rotateZ( 20deg); } }

@-ms-keyframes tilt {
0% {
 -webkit-transform: rotateX(-30deg);
 -moz-transform: rotateX(-30deg);
 transform: rotateX(-30deg); }

25% {
 -webkit-transform: rotateX( 30deg);
 -moz-transform: rotateX( 30deg);
 transform: rotateX( 30deg); }

50% {
 -webkit-transform: rotateY(-30deg);
 -moz-transform: rotateY(-30deg);
 transform: rotateY(-30deg); }

75% {
 -webkit-transform: rotateY( 30deg);
 -moz-transform: rotateY( 30deg);
 transform: rotateY( 30deg); }

100% {
 -webkit-transform: rotateZ( 20deg);
 -moz-transform: rotateZ( 20deg);
 transform: rotateZ( 20deg); } }

@keyframes tilt {
0% {
 -webkit-transform: rotateX(-30deg);
 -moz-transform: rotateX(-30deg);
 transform: rotateX(-30deg); }

25% {
 -webkit-transform: rotateX( 30deg);
 -moz-transform: rotateX( 30deg);
 transform: rotateX( 30deg); }

50% {
 -webkit-transform: rotateY(-30deg);
 -moz-transform: rotateY(-30deg);
 transform: rotateY(-30deg); }

75% {
 -webkit-transform: rotateY( 30deg);
 -moz-transform: rotateY( 30deg);
 transform: rotateY( 30deg); }

100% {
 -webkit-transform: rotateZ( 20deg);
 -moz-transform: rotateZ( 20deg);
 transform: rotateZ( 20deg); } }

@-webkit-keyframes wave {
0% {
 -webkit-transform: rotateZ(0deg) translate3d(0,10%,0) rotateZ(0deg);
 -moz-transform: rotateZ(0deg) translate3d(0,10%,0) rotateZ(0deg);
 transform: rotateZ(0deg) translate3d(0,10%,0) rotateZ(0deg); }

100% {
 -webkit-transform: rotateZ(360deg) translate3d(0,10%,0) rotateZ(-360deg);
 -moz-transform: rotateZ(360deg) translate3d(0,10%,0) rotateZ(-360deg);
 transform: rotateZ(360deg) translate3d(0,10%,0) rotateZ(-360deg); } }

@-moz-keyframes wave {
0% {
 -webkit-transform: rotateZ(0deg) translate3d(0,10%,0) rotateZ(0deg);
 -moz-transform: rotateZ(0deg) translate3d(0,10%,0) rotateZ(0deg);
 transform: rotateZ(0deg) translate3d(0,10%,0) rotateZ(0deg); }

100% {
 -webkit-transform: rotateZ(360deg) translate3d(0,10%,0) rotateZ(-360deg);
 -moz-transform: rotateZ(360deg) translate3d(0,10%,0) rotateZ(-360deg);
 transform: rotateZ(360deg) translate3d(0,10%,0) rotateZ(-360deg); } }

@-ms-keyframes wave {
0% {
 -webkit-transform: rotateZ(0deg) translate3d(0,10%,0) rotateZ(0deg);
 -moz-transform: rotateZ(0deg) translate3d(0,10%,0) rotateZ(0deg);
 transform: rotateZ(0deg) translate3d(0,10%,0) rotateZ(0deg); }

100% {
 -webkit-transform: rotateZ(360deg) translate3d(0,10%,0) rotateZ(-360deg);
 -moz-transform: rotateZ(360deg) translate3d(0,10%,0) rotateZ(-360deg);
 transform: rotateZ(360deg) translate3d(0,10%,0) rotateZ(-360deg); } }

@keyframes wave {
0% {
 -webkit-transform: rotateZ(0deg) translate3d(0,10%,0) rotateZ(0deg);
 -moz-transform: rotateZ(0deg) translate3d(0,10%,0) rotateZ(0deg);
 transform: rotateZ(0deg) translate3d(0,10%,0) rotateZ(0deg); }

100% {
 -webkit-transform: rotateZ(360deg) translate3d(0,10%,0) rotateZ(-360deg);
 -moz-transform: rotateZ(360deg) translate3d(0,10%,0) rotateZ(-360deg);
 transform: rotateZ(360deg) translate3d(0,10%,0) rotateZ(-360deg); } }

@-webkit-keyframes lighthouse {
0% {
 -webkit-transform: translate3d(15%,0,0) rotateZ(10deg);
 -moz-transform: translate3d(15%,0,0) rotateZ(10deg);
 transform: translate3d(15%,0,0) rotateZ(10deg); }

100% {
 -webkit-transform: translate3d(-15%,0,0) rotateZ(-10deg);
 -moz-transform: translate3d(-15%,0,0) rotateZ(-10deg);
 transform: translate3d(-15%,0,0) rotateZ(-10deg); } }

@-moz-keyframes lighthouse {
0% {
 -webkit-transform: translate3d(15%,0,0) rotateZ(10deg);
 -moz-transform: translate3d(15%,0,0) rotateZ(10deg);
 transform: translate3d(15%,0,0) rotateZ(10deg); }

100% {
 -webkit-transform: translate3d(-15%,0,0) rotateZ(-10deg);
 -moz-transform: translate3d(-15%,0,0) rotateZ(-10deg);
 transform: translate3d(-15%,0,0) rotateZ(-10deg); } }

@-ms-keyframes lighthouse {
0% {
 -webkit-transform: translate3d(15%,0,0) rotateZ(10deg);
 -moz-transform: translate3d(15%,0,0) rotateZ(10deg);
 transform: translate3d(15%,0,0) rotateZ(10deg); }

100% {
 -webkit-transform: translate3d(-15%,0,0) rotateZ(-10deg);
 -moz-transform: translate3d(-15%,0,0) rotateZ(-10deg);
 transform: translate3d(-15%,0,0) rotateZ(-10deg); } }

@keyframes lighthouse {
0% {
 -webkit-transform: translate3d(15%,0,0) rotateZ(10deg);
 -moz-transform: translate3d(15%,0,0) rotateZ(10deg);
 transform: translate3d(15%,0,0) rotateZ(10deg); }

100% {
 -webkit-transform: translate3d(-15%,0,0) rotateZ(-10deg);
 -moz-transform: translate3d(-15%,0,0) rotateZ(-10deg);
 transform: translate3d(-15%,0,0) rotateZ(-10deg); } }

@-webkit-keyframes swing {
0% {
 -webkit-transform: rotateZ(10deg);
 -moz-transform: rotateZ(10deg);
 transform: rotateZ(10deg); }

100% {
 -webkit-transform: rotateZ(-10deg);
 -moz-transform: rotateZ(-10deg);
 transform: rotateZ(-10deg); } }

@-moz-keyframes swing {
0% {
 -webkit-transform: rotateZ(10deg);
 -moz-transform: rotateZ(10deg);
 transform: rotateZ(10deg); }

100% {
 -webkit-transform: rotateZ(-10deg);
 -moz-transform: rotateZ(-10deg);
 transform: rotateZ(-10deg); } }

@-ms-keyframes swing {
0% {
 -webkit-transform: rotateZ(10deg);
 -moz-transform: rotateZ(10deg);
 transform: rotateZ(10deg); }

100% {
 -webkit-transform: rotateZ(-10deg);
 -moz-transform: rotateZ(-10deg);
 transform: rotateZ(-10deg); } }

@keyframes swing {
0% {
 -webkit-transform: rotateZ(10deg);
 -moz-transform: rotateZ(10deg);
 transform: rotateZ(10deg); }

100% {
 -webkit-transform: rotateZ(-10deg);
 -moz-transform: rotateZ(-10deg);
 transform: rotateZ(-10deg); } }

